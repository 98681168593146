import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostAllBanksFireServiceMutation } from "../../../redux/reports/reportsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const ReportGeneralFireService = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);
	const [customData, setCustomData] = useState<any>([]);
	const [groupColumns, setGroupColuns] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] = usePostAllBanksFireServiceMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const generateCustomData = (results: any) => {
		const newData: any = [];
		const newGroupColumns: any = [];
		results.map((item: any) => {
			const transformedItem: any = {};
			Object.keys(item).forEach((month) => {
				if (month !== "bank_name" && month !== "lift_name") {
					newGroupColumns.push(month);
					const monthData = item[month];
					transformedItem[`${month}_PH1`] = monthData.PH1;
					transformedItem[`${month}_PH2`] = monthData.PH2;
				} else {
					transformedItem[month] = item[month];
				}
			});
			newData.push(transformedItem);
			return true;
		});
		setCustomData({ results: newData });
		const uniqueGroupColumns = newGroupColumns.filter(
			(item: string, index: any) => newGroupColumns.indexOf(item) === index
		);
		const newGroupColumns2: any = [];
		uniqueGroupColumns.map((item: string) => {
			newGroupColumns2.push({
				name: item,
				data: [`${item}_PH1`, `${item}_PH2`],
			});
			return true;
		});
		setGroupColuns(newGroupColumns2);
	};

	const generateTableRequestBody = ({ downloadReport }: any) => {
		const body = {
			server_id: filters.serverId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			minimum_seconds: filters.dateTimeSeconds,
			download: downloadReport,
		};
		return body;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	useEffect(() => {
		if (data && data.results) {
			generateCustomData(data.results);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission("view-report-management-general-fire-service", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "SERVERS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: ["bank_name", "lift_name"],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText3",
							displayDownloadCSVReport: true,
							displayDownloadPDFReport: true,
							reportTitle: "General / Fire Service",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: true,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
								{ id: "MONTH", name: "Month" },
								{ id: "QUARTER", name: "Quarter" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: true,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
						}}
					/>
					<ReportsTable
						response={customData}
						generateTableRequestBody={generateTableRequestBody}
						csvUrl={`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/all-banks/fire-service`}
						displayExpandibleRowTable={false}
						groupColumns={groupColumns}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportGeneralFireService;
