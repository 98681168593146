import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useLazyGetVendorsQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// INTERFACES
import { FormSelectVendorInterface } from "../../../interfaces/Global.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function FormSelectVendors({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
}: FormSelectVendorInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [vendors, setVendors] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetVendors, { data, isLoading }] = useLazyGetVendorsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetVendors({
			token,
		});
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setVendors(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Select
						className='generalStyles__width100'
						size='small'
						onChange={(e: any) => {
							inputOnChange(e, inputVariableName);
						}}
						value={inputValue}
						loading={isLoading}
					>
						{vendors.map((item: any) => (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						))}
					</Select>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormSelectVendors;
