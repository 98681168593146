import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenModalCreateEditNewConfigurationFile,
	updateConfigurationFileRefreshTable,
	updateFormCreateEdit,
} from "../../../redux/configurationFiles/configurationFilesSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

library.add(faPlus, faArrowsRotate);

function ConfigurationFilesHeader() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { permissions } = useSelector((state: any) => state.user);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewConfigurationFile = () => {
		dispatch(updateOpenModalCreateEditNewConfigurationFile(true));
		dispatch(updateFormCreateEdit("CREATE"));
	};

	const handleReloadPage = () => {
		dispatch(updateConfigurationFileRefreshTable(true));
		setTimeout(() => {
			dispatch(updateConfigurationFileRefreshTable(false));
		}, 1000);
	};

	return (
		<div className='generalStyles__pageHeader'>
			{validatePermission("configurations-create", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						type='primary'
						icon={<FontAwesomeIcon icon={faPlus} className='generalStyles__mrFix' />}
						onClick={handleOpenModalCreateNewConfigurationFile}
					>
						{t("configuration.configurationFiles.createNewConfigurationFile")}
					</Button>
				</div>
			)}
			{validatePermission("configurations-view", permissions) && (
				<div className='generalStyles__mlFix mt-1'>
					<Button
						type='primary'
						icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
						onClick={() => {
							handleReloadPage();
						}}
					>
						{t("configuration.configurationFiles.refreshTable")}
					</Button>
				</div>
			)}
		</div>
	);
}

export default ConfigurationFilesHeader;
