import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Drawer } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { faBuildingCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenModalCreateNewOrganization,
	updateOrganization,
} from "../../../redux/organizations/organizationSlice";
import { usePostOrganizationMutation, usePutOrganizationMutation } from "../../../redux/organizations/organizationAPI";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
import FormInputText from "../../components/form/FormInputText";
import FormInputPhoneNumber from "../../components/form/FormInputPhoneNumber";
import FormInputSelectCountry from "../../components/form/FormInputSelectCountry";
import FormInputSelectState from "../../components/form/FormInputSelectState";
import FormInputSelectCity from "../../components/form/FormInputSelectCity";
import FormInputTextDisabled from "../../components/form/FormInputTextDisabled";
import OrganizationSelectOrganizationSubOrganization from "./OrganizationSelectOrganizationSubOrganization";
import GlobalAlert2 from "../../home/GlobalAlert2";
import ButtonClose from "../../components/buttons/ButtonClose";
import ButtonSave from "../../components/buttons/ButtonSave";
// INTERFACES
import { RootState } from "../../../app/store";
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function OrganizationCreateEditForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const ORGANIZATION_TEMPLATE = {
		id: "",
		address: "",
		city: "",
		country: "",
		name: "",
		phone_code: "+1",
		phone: "",
		state: "",
		type: "ORG",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [organization, setOrganization] = useState<OrganizationInterface>(ORGANIZATION_TEMPLATE);
	const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const {
		openModalCreateNewOrganization,
		organizationAndChilds,
		formCreateEdit,
		organization: organizationSelected,
	} = useSelector((state: any) => state.organization);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerPostOrganization,
		{
			isSuccess: isSuccessPostOrganization,
			isLoading: isLoadingPostOrganization,
			isError: isErrorPostOrganization,
			error: errorPostOrganization,
			reset: resetPostOrganization,
		},
	] = usePostOrganizationMutation();

	const [
		triggerPutOrganization,
		{
			isSuccess: isSuccessPutOrganization,
			isError: isErrorPutOrganization,
			error: errorPutOrganization,
			isLoading: isLoadingPutOrganization,
			reset: resetPutOrganization,
		},
	] = usePutOrganizationMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		resetPutOrganization();
		resetPostOrganization();
		setOrganization(ORGANIZATION_TEMPLATE);
		dispatch(updateOpenModalCreateNewOrganization(false));
		dispatch(updateOrganization(ORGANIZATION_TEMPLATE));
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy: OrganizationInterface = structuredClone(organization);
		copy[variableName] = newValue;
		setOrganization(copy);
	};

	const onChangeParentOrgId = () => {
		const copy: OrganizationInterface = structuredClone(organization);
		if (organizationAndChilds && organizationAndChilds.data && organizationAndChilds.data.idOrganizationSelected) {
			copy.parent_id = organizationAndChilds.data.idOrganizationSelected;
			if (organizationAndChilds.data.idOrganizationSelected !== process.env.REACT_APP_MASTER_ORG_ID) {
				copy.type = "SUBORG";
			}
		}
		setOrganization(copy);
	};

	const onClickSave = async () => {
		const token = await GETJwtToken();
		const copyOrganization: any = structuredClone(organization);
		delete copyOrganization.id;
		if (formCreateEdit === "CREATE" || formCreateEdit === "ASSIGN") {
			const BODY = {
				body: copyOrganization,
				token,
			};
			triggerPostOrganization(BODY);
		} else if (formCreateEdit === "EDIT") {
			const BODY = {
				id: organization.id,
				body: copyOrganization,
				token,
			};
			triggerPutOrganization(BODY);
		}
	};

	const disableSaveButton = () => {
		if (
			organization.name !== "" &&
			organization.address !== "" &&
			organization.phone !== "" &&
			organization.type !== "" &&
			organization.country !== "" &&
			organization.parent_id !== "" &&
			organization.state !== "" &&
			organization.city !== ""
		) {
			return false;
		}
		return true;
	};

	const onFillEditOrganization = () => {
		setOrganization(organizationSelected);
	};

	const onFillAssignOrganization = () => {
		const copy: OrganizationInterface = structuredClone(organization);
		copy.parent_id = organizationSelected.id;
		copy.address = organizationSelected.address;
		copy.country = organizationSelected.country;
		copy.state = organizationSelected.state;
		copy.city = organizationSelected.city;
		copy.phone = organizationSelected.phone;
		copy.type = "SUBORG";
		setOrganization(copy);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (formCreateEdit === "EDIT") {
			onFillEditOrganization();
		} else if (formCreateEdit === "ASSIGN") {
			onFillAssignOrganization();
		}
	}, [openModalCreateNewOrganization]);

	useEffect(() => {
		onChangeParentOrgId();
	}, [organizationAndChilds]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPostOrganization, isSuccessPutOrganization, isErrorPostOrganization, isErrorPutOrganization]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer width='550' placement='right' onClose={onClickClose} closable={false} open={openModalCreateNewOrganization}>
			<Spin
				spinning={organizationsAndChildsIsLoading || isLoadingPostOrganization || isLoadingPutOrganization}
				tip={t("general.loading")}
			>
				<DrawerRowDivider
					icon={faBuildingCircleArrowRight}
					title={
						formCreateEdit === "EDIT"
							? t("organizationManagement.editOrganization")
							: t("organizationManagement.createNewOrganization")
					}
				/>
				<div className='container'>
					<div className={`drawer__box__${theme} row`}>
						{/** COMPONENTS FORM - INPUT NAME */}
						<FormInputText
							inputValue={organization.name}
							inputLabelName={t("general.name")}
							inputOnChange={onChangeFormValue}
							inputVariableName='name'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT ADDRESS */}
						<FormInputText
							inputValue={organization.address}
							inputLabelName={t("organizationManagement.address")}
							inputOnChange={onChangeFormValue}
							inputVariableName='address'
							isRequired
							disabledInput={false}
						/>
						{/** COMPONENTS FORM - INPUT COUNTRY */}
						<FormInputSelectCountry
							inputValue={organization.country}
							inputLabelName={t("general.country")}
							inputOnChange={onChangeFormValue}
							inputVariableName='country'
						/>
						{/** COMPONENTS FORM - INPUT STATE */}
						<FormInputSelectState
							inputValue={organization.state}
							inputLabelName={t("general.state")}
							inputOnChange={onChangeFormValue}
							inputVariableName='state'
							country={organization.country}
						/>
						{/** COMPONENTS FORM - INPUT CITY */}
						<FormInputSelectCity
							inputValue={organization.city}
							inputLabelName={t("general.city")}
							inputOnChange={onChangeFormValue}
							inputVariableName='city'
							country={organization.country}
							state={organization.state}
						/>
						{/** COMPONENTS FORM - INPUT PHONE NUMBER */}
						<FormInputPhoneNumber
							inputValue={organization.phone}
							inputLabelName={t("userManagement.phone")}
							inputOnChange={onChangeFormValue}
							inputVariableName='phone'
						/>
					</div>
				</div>
				{formCreateEdit === "CREATE" && (
					<div className='container'>
						{/** COMPONENT - GET ORGANIZATION AND CHILDS  */}
						<OrganizationSelectOrganizationSubOrganization
							setOrganizationsAndChildsIsLoading={setOrganizationsAndChildsIsLoading}
						/>
					</div>
				)}
				{formCreateEdit === "ASSIGN" && (
					<>
						<br />
						<DrawerRowDivider
							icon={faBuildingCircleArrowRight}
							title={t("organizationManagement.assignToOrganization")}
						/>
						<div className='container'>
							<div className={`drawer__box__${theme} row`}>
								{/** COMPONENTS FORM - INPUT ORGANIZATION PARENT NAME */}
								<FormInputTextDisabled inputValue={organizationSelected.name} inputLabelName={t("general.name")} />
							</div>
						</div>
					</>
				)}
				<div className='container generalStyles__flex mt-4'>
					<div className='generalStyles__width100 generalStyles__mrFix'>
						<ButtonClose onClick={onClickClose} />
					</div>
					<div className='generalStyles__width100 generalStyles__mlFix'>
						<ButtonSave onClick={onClickSave} disabledButton={disableSaveButton()} />
					</div>
				</div>
			</Spin>
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostOrganization}
				isSuccess={isSuccessPostOrganization}
				requestType='POST'
				error={errorPostOrganization}
				name='Organization'
			/>
			<GlobalAlert2
				isError={isErrorPutOrganization}
				isSuccess={isSuccessPutOrganization}
				requestType='PUT'
				error={errorPutOrganization}
				name='Organization'
			/>
		</Drawer>
	);
}

export default OrganizationCreateEditForm;
