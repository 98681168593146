import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, InputNumber, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetConfigurationFilesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import DrawerRowDivider from "../../components/drawer/DrawerRowDivider";
// INTERFACES
import { FloorsAndDirectionDataInterface } from "../../../interfaces/Configurations.interface";

function ConfigurationFilesAssignConfiguration({ setDataBlocks, liftType, setFloorsAndDirectionData }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<FloorsAndDirectionDataInterface>({
		numberOfLifts: 0,
		numberOfStops: 2,
		maxNumberOfStops: 0,
		numberOfFloorsBelowLobby: 0,
		configurationId: "",
		adapterId: "",
	});
	const [configurationSelected, setConfigurationSelected] = useState("");
	const [configurationFiles, setConfigurationFiles] = useState<any>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme, userLogged } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetConfigurationFilesTable, { data: dataConfigurationFiles }] = useLazyGetConfigurationFilesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeData = (newValues: any, nameValues: any) => {
		const copy = structuredClone(data);
		nameValues.map((value: any, index: number) => {
			copy[value] = newValues[index];
			return true;
		});
		setData(copy);
	};

	const onChangeConfiguration = (newValue: string) => {
		const index = configurationFiles.findIndex((object: any) => object.id === newValue);
		const newArr: any[] = [];
		if (configurationFiles && configurationFiles[index] && configurationFiles[index].data_blocks) {
			configurationFiles[index].data_blocks.map((block: any) => newArr.push(block.id));
		}
		setDataBlocks(newArr);
		setConfigurationSelected(newValue);
	};

	const getConfigurationFiles = async (orgId: string) => {
		const token = await GETJwtToken();
		triggerGetConfigurationFilesTable({
			token,
			orgId,
			page: 1,
			limit: 9999,
		});
	};

	const filterConfigurationFiles = () => {
		const newObj: any = [];
		if (dataConfigurationFiles && dataConfigurationFiles.data) {
			dataConfigurationFiles.data.map((item: any) => {
				if (liftType === "ELEVATOR") {
					if (data.numberOfLifts <= item.lifts && data.numberOfStops <= item.stops && item.lift_type === liftType) {
						newObj.push(item);
					}
				} else if (liftType === "ESCALATOR" || liftType === "MOVINGWALK") {
					if (data.numberOfLifts <= item.lifts && item.lift_type === liftType) {
						newObj.push(item);
					}
				}
				return true;
			});
		}
		setConfigurationFiles(newObj);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (userLogged && userLogged.org && userLogged.org.id) {
			getConfigurationFiles(userLogged.org.id);
		}
	}, []);

	useEffect(() => {
		filterConfigurationFiles();
	}, [dataConfigurationFiles]);

	useEffect(() => {
		filterConfigurationFiles();
	}, [data.numberOfLifts, data.numberOfStops, liftType]);

	useEffect(() => {
		if (data) {
			setFloorsAndDirectionData(data);
		}
	}, [data]);

	useEffect(() => {
		const copy = structuredClone(data);
		copy.configurationId = configurationSelected;
		setData(copy);
	}, [configurationSelected]);

	useEffect(() => {
		// Update lif type acording lift selection
		setConfigurationSelected("");
		if (liftType === "ESCALATOR") {
			setData({
				numberOfLifts: 0,
				numberOfStops: 2,
				maxNumberOfStops: 0,
				numberOfFloorsBelowLobby: 0,
				configurationId: "",
				adapterId: "",
			});
		} else if (liftType === "MOVINGWALK") {
			setData({
				numberOfLifts: 0,
				numberOfStops: 2,
				maxNumberOfStops: 0,
				numberOfFloorsBelowLobby: 0,
				configurationId: "",
				adapterId: "",
			});
		} else if (liftType === "ELEVATOR") {
			setData({
				numberOfLifts: 0,
				numberOfStops: 2,
				maxNumberOfStops: 0,
				numberOfFloorsBelowLobby: 0,
				configurationId: "",
				adapterId: "",
			});
		}
	}, [liftType]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faGear} title={t("general.configuration")} />
			<div className={`drawer__box__${theme}`}>
				{/** CONFIGURATION */}
				<div className='generalStyles__flex drawer__container'>
					<div>
						<p className='drawer__title'>
							{" "}
							<span className='generalStyles__inputFlexRequired'>*</span>
							{t("general.name")}
						</p>
					</div>
					<div
						className=' drawer__textContainer generalStyles__flex generalStyles__textEllipsis'
						style={{ width: "333px" }}
					>
						<Select
							value={configurationSelected}
							onChange={(e: any) => {
								onChangeConfiguration(e);
								const index = configurationFiles.findIndex((item: any) => item.id === e);
								onChangeData(
									[configurationFiles[index].lifts, configurationFiles[index].stops],
									["numberOfLifts", "maxNumberOfStops"]
								);
							}}
							className='generalStyles__width100 '
							size='small'
						>
							{configurationFiles.map((item: any) => (
								<Option key={item.id} value={item.id}>
									<span style={{ fontSize: "10px" }}>{item.name}</span> - Lifts:
									<span className='generalStyles__info'> {item.lifts}</span>
									{"  "}
									{liftType === "ELEVATOR" && (
										<>
											Stops:
											<span className='generalStyles__info'> {item.stops}</span>
										</>
									)}
								</Option>
							))}
						</Select>
					</div>
				</div>
				{/** Configuration type ELEVATOR */}
				{liftType === "ELEVATOR" && (
					<>
						{/** STOPS */}
						<div className='generalStyles__flex drawer__container mt-2'>
							<div>
								<p className='drawer__title'>
									{" "}
									<span className='generalStyles__inputFlexRequired'>*</span>
									{t("configuration.configurationFiles.stops")}
								</p>
							</div>
							<div className='generalStyles__width100 drawer__textContainer generalStyles__flex'>
								<InputNumber
									className='generalStyles__inputNumberFull'
									precision={0}
									value={data.numberOfStops}
									onChange={(e: any) => {
										onChangeData([e, 0], ["numberOfStops", "numberOfFloorsBelowLobby"]);
									}}
									max={data.maxNumberOfStops}
									size='small'
									disabled={configurationSelected === ""}
								/>
								<div className='generalStyles__infoTooltipIconBlank'>
									<Tooltip title={`${t("tooltip.maxStopsAllowed")} ${data.maxNumberOfStops}`} placement='top'>
										<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon' />
									</Tooltip>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default ConfigurationFilesAssignConfiguration;
