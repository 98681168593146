import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Input, Button, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { usePostFavoriteGridsMutation } from "../../../redux/monitoring/monitoringAPI";
import { updateOpenModalSaveGrid } from "../../../redux/monitoring/monitoringSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACECES
import { RootState } from "../../../app/store";
// COMPONENTS
import GlobalAlert2 from "../../home/GlobalAlert2";

function MonitoringModalSaveNewGrid() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [favoriteGridName, setFavoriteGridName] = useState("");

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const {
    cascaderSelectMultipleSelectionOptions,
    topViewLayout,
    detailViewLayout,
    statusViewLayout,
    openModalSaveGrid,
  } = useSelector((state: RootState) => state.monitoring);
  const { serviceModeEnabled, floorsEnabled, namesEnabled, showOriginal } =
    useSelector((state: any) => state.mapView);
  const { organizationSelectMultipleData } = useSelector(
    (state: RootState) => state.organization
  );
  const { userLogged } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postSaveConfiguration,
    {
      isSuccess: isSuccessPostSaveConfiguration,
      isLoading: isLoadingPostSaveConfiguration,
      isError: isErrorPostSaveConfiguration,
      error: errorPostSaveConfiguration,
      reset: resetPostSaveConfiguration,
    },
  ] = usePostFavoriteGridsMutation();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const saveCurrentConfiguration = async () => {
    const token = await GETJwtToken();
    const mapViewConfiguration = {
      serviceModeEnabled,
      floorsEnabled,
      namesEnabled,
      showOriginal,
    };
    const BODY = {
      token,
      body: {
        grid: JSON.stringify({
          topViewLayout,
          detailViewLayout,
          statusViewLayout,
          organizationSelectMultipleData,
          cascaderSelectMultipleSelectionOptions,
          mapViewConfiguration,
        }),
        name: favoriteGridName,
        query: JSON.stringify(cascaderSelectMultipleSelectionOptions),
        user_id: userLogged.id,
      },
    };
    postSaveConfiguration(BODY);
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    resetPostSaveConfiguration();
  }, [isSuccessPostSaveConfiguration, isErrorPostSaveConfiguration]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      closable={false}
      onCancel={() => {
        dispatch(updateOpenModalSaveGrid(false));
      }}
      open={openModalSaveGrid}
      footer={null}
    >
      <Spin spinning={isLoadingPostSaveConfiguration}>
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSave}
            className="generalStyles__info generalStyles__deleteItemIcon"
          />
          <p className="mt-3 drawer__text">
            {t("monitoring.favoriteGridDescription")}
          </p>
          <Input
            placeholder={t("general.name")}
            onChange={(e) => {
              setFavoriteGridName(e.target.value);
            }}
            value={favoriteGridName}
          />
        </div>
        <div className="generalStyles__flexEnd mt-4">
          <div>
            <Button
              htmlType="submit"
              className={`generalStyles__mrFix ${
                favoriteGridName.length > 0 ? "buttonStyle__1" : ""
              }`}
              onClick={saveCurrentConfiguration}
              disabled={favoriteGridName.length === 0}
            >
              {t("general.save")}
            </Button>
            <Button
              onClick={() => {
                dispatch(updateOpenModalSaveGrid(false));
              }}
              className="buttonStyle__8"
            >
              {t("general.close")}
            </Button>
          </div>
        </div>
      </Spin>
      {/** ---------------------------------------------------- */}
      {/** GLOBAL ALERT */}
      <GlobalAlert2
        isError={isErrorPostSaveConfiguration}
        isSuccess={isSuccessPostSaveConfiguration}
        requestType="POST"
        error={errorPostSaveConfiguration}
        name="Configuration"
      />
    </Modal>
  );
}

export default MonitoringModalSaveNewGrid;
