import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
// COMPONENTS
import { MapViewPin } from "../../../interfaces/MapView.interface";
// AUTHORIZATION
// REDUX
import { updateMapViewPinArray } from "../../../redux/mapView/mapViewSlice";
import { RootState } from "../../../app/store";
import MonitoringMapViewList from "./MonitoringMapViewList";
import MonitoringMapViewConfig from "./MonitoringMapViewConfig";
import MonitoringMapViewWrapper from "./MonitoringMapViewWrapper";

function MonitoringMapView({ lastSocketMessage, layouts }: { lastSocketMessage: any; layouts: any }) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const divRef = React.useRef<HTMLDivElement>(null);
	const [svgContent, setSvgContent] = React.useState<any>(null);
	const [faults, setFaults] = React.useState<any[]>([]);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [buttonProperties, setButtonProperties] = React.useState({
		width: 0,
		height: 0,
	});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { mapViewPinArray, mapViewItem, mapViewArray, showOriginal } = useSelector((state: any) => state.mapView);
	const { theme } = useSelector((state: RootState) => state.home);
	const { existsFaultsInMonitoringView } = useSelector((state: any) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleResize = () => {
		const width = divRef.current?.offsetWidth || 0;
		const height = divRef.current?.offsetHeight || 0;
		if (width === 0 || height === 0) return;
		setButtonProperties({
			width,
			height,
		});
	};

	const fetchSvg = (svgUrl: string) => {
		// Replace with your S3 UR
		fetch(svgUrl, {
			method: "GET",
			headers: {
				"Content-Type": "image/svg+xml",
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.text();
			})
			.then((text) => {
				setSvgContent(text);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.error("Error fetching the SVG:", error);
			});
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	React.useEffect(() => {
		if (!mapViewItem?.s3_url) return;
		fetchSvg(mapViewItem.s3_url);
		const pins = mapViewItem?.setup?.pins ?? [];
		dispatch(
			updateMapViewPinArray(
				pins?.map((pin: any) => ({
					...pin,
				}))
			)
		);
	}, [mapViewItem]);

	React.useEffect(() => {
		window.addEventListener("resize", handleResize, false);
		handleResize();
	}, [divRef.current?.clientWidth, divRef.current?.clientHeight]);

	React.useEffect(() => {
		if (!svgContent) return;
		// replace the first ocurrence of width and height
		let modifiedSvg = svgContent;
		modifiedSvg = modifiedSvg.replace(/width="[^"]+"/, `width="100%"`);
		modifiedSvg = modifiedSvg.replace(/height="[^"]+"/, `height="100%"`);
		setSvgContent(modifiedSvg);
		if (showOriginal) return;
		switch (theme) {
			case "light":
				modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#000000"'); // Cambia los colores a blanco
				modifiedSvg = modifiedSvg.replace(/fill:\s*#[0-9a-fA-F]{3,6};?/g, "fill:#000000;");
				modifiedSvg = modifiedSvg.replace(/stroke:\s*#[0-9a-fA-F]{3,6};?/g, "stroke:#f0f2f5;");
				break;
			case "dark":
				modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#ffffff"'); // Cambia los colores a negro
				modifiedSvg = modifiedSvg.replace(/fill:\s*#[0-9a-fA-F]{3,6};?/g, "fill:#ffffff;");
				modifiedSvg = modifiedSvg.replace(/stroke:\s*#[0-9a-fA-F]{3,6};?/g, "stroke:#000000;");
				break;
			case "blue":
				modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#ffffff"');
				modifiedSvg = modifiedSvg.replace(/fill:\s*#[0-9a-fA-F]{3,6};?/g, "fill:#ffffff;");
				modifiedSvg = modifiedSvg.replace(/stroke:\s*#[0-9a-fA-F]{3,6};?/g, "stroke:#0c1a28;");
				break;
			default:
				modifiedSvg = modifiedSvg.replace(/fill="[^"]+"/g, 'fill="#FFFFFF"');
				break;
		}
		setSvgContent(modifiedSvg);
	}, [svgContent, theme]);

	React.useEffect(() => {
		if (!mapViewItem?.s3_url) return;
		if (showOriginal) {
			fetchSvg(mapViewItem.s3_url);
		} else {
			setSvgContent(null);
			fetchSvg(mapViewItem.s3_url);
		}
	}, [showOriginal]);

	React.useEffect(() => {
		if (divRef.current) {
			divRef.current.innerHTML = svgContent;
			handleResize();
		}
	}, [svgContent]);

	React.useEffect(() => {
		if (!existsFaultsInMonitoringView) {
			setFaults([]);
		}
	}, [existsFaultsInMonitoringView]);

	React.useEffect(() => {
		if (!lastSocketMessage) return;
		const isFaultOrError =
			lastSocketMessage.icon.type.toUpperCase() === "FAULT" || lastSocketMessage.icon.type.toUpperCase() === "ERROR";
		// FAULT MESSAGE
		if (isFaultOrError) {
			setFaults([...faults, lastSocketMessage]);
		}
	}, [lastSocketMessage]);

	// ************************************************ */
	// COMPONENT ************************************** */
	if (!mapViewArray?.length) return <>Select one organization</>;
	return (
		<>
			{/* button group */}
			<Row style={{ display: "flex", justifyContent: "space-between" }}>
				<Col span={11}>
					<MonitoringMapViewList faults={faults} />
				</Col>
				<Col span={1}>
					<MonitoringMapViewConfig />
				</Col>
			</Row>
			{/** BODY */}
			<Row
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "1rem",
					width: "100%",
				}}
			>
				<div
					style={{
						position: "relative",
						width: "75%",
						height: "fit-content",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{/* map view */}
					<div
						style={{
							width: "100%",
							height: "fit-content",
						}}
					>
						{svgContent && <div ref={divRef} />}
						{mapViewPinArray?.map((lift: MapViewPin) => {
							if (!lift.x || !lift.y) return null;
							return (
								<MonitoringMapViewWrapper
									key={lift.id}
									id={lift.id}
									text={lift.text}
									direction={lift.direction}
									type={lift.type}
									x={lift.x * buttonProperties.width}
									y={lift.y * buttonProperties.height}
									lastSocketMessage={lastSocketMessage}
									layouts={layouts}
									faults={faults}
								/>
							);
						})}
						<div />
					</div>
				</div>
			</Row>
		</>
	);
}

export default MonitoringMapView;
