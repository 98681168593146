import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Checkbox, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTable } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateTerminalResetTable,
	updateTerminalShowJsonObject,
	updateTerminalAutoScroll,
	updateTerminalShowAllMessages,
	updateTerminalDownloadReport,
	updateLiftSelected,
	updateBankSelected,
	updateTerminalIdLiftSelected,
	updateTerminalLastShortMessage,
} from "../../../redux/monitoring/monitoringSlice";
// INTERFACES
import { RootState } from "../../../app/store";
// INTERFACES
import { LiftInterface, LayoutInterface, TerminalLiftsInterface } from "../../../interfaces/EdgeManagement.interface";

function TerminalFilters({ layouts }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const { Option } = Select;
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		bank_name: "",
		lift_name: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			down: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
			down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
		},
		faults: false,
		position: 1,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
		direction: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [lifts, setLifts] = useState<TerminalLiftsInterface[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { terminalShowJsonObject, terminalAutoScroll, terminalShowAllMessages, liftSelected } = useSelector(
		(state: RootState) => state.monitoring
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickResetTable = () => {
		dispatch(updateTerminalResetTable(true));
		dispatch(updateTerminalLastShortMessage(BLANK_SOCKET_MESSAGE));
		setTimeout(() => {
			dispatch(updateTerminalResetTable(false));
		}, 100);
	};

	const onChangeShowJsonObject = (newValue: boolean) => {
		dispatch(updateTerminalShowJsonObject(newValue));
	};

	const onChangeAutoScroll = (newValue: boolean) => {
		dispatch(updateTerminalAutoScroll(newValue));
	};

	const onChangeShowAllMessages = (newValue: boolean) => {
		dispatch(updateTerminalShowAllMessages(newValue));
		dispatch(updateTerminalAutoScroll(false));
	};

	const onClickDownloadExcel = () => {
		dispatch(updateTerminalDownloadReport(true));
		setTimeout(() => {
			dispatch(updateTerminalDownloadReport(false));
		}, 100);
	};

	const onChangeFilterLift = (newValue: string) => {
		if (newValue) {
			if (layouts) {
				// Save lift selected
				layouts.map((layout: LayoutInterface) => {
					if (layout && layout.lifts) {
						layout.lifts.map((lift: LiftInterface) => {
							if (lift && lift.id === newValue) {
								dispatch(updateLiftSelected(lift));
								dispatch(updateTerminalIdLiftSelected(lift.id));
							}
							return true;
						});
					}
					return true;
				});
			}
		} else {
			dispatch(
				updateLiftSelected({
					id: "",
					bank_id: "",
					control_panel: {},
					direction: "",
					name: "",
					position: 0,
					type: "",
					lift_floors: [],
				})
			);
			dispatch(
				updateBankSelected({
					id: "",
					active: false,
					board: 0,
					columns: 0,
					floor_below_lobby: 0,
					floor_count: 0,
					logical_port: 0,
					name: "",
					number_of_lifts: 0,
					channel_id: "",
					rear_doors: false,
					stops: 0,
					type: "",
					control_panel: {},
					bank_floors: [],
					lifts: [],
				})
			);
			onClickResetTable();
		}
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		const newArr: TerminalLiftsInterface[] = [];
		if (layouts) {
			layouts.map((layout: LayoutInterface) => {
				if (layout && layout.lifts) {
					layout.lifts.map((lift: LiftInterface) => {
						newArr.push({ id: lift.id, name: lift.name });
						return true;
					});
				}
				return true;
			});
		}
		setLifts(newArr);
	}, [layouts]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flex'>
			{/** LIFT */}
			<div className='col-md-12'>
				<div className='generalStyles__spaceBetween'>
					<div style={{ width: "250px" }}>
						<Select
							className='generalStyles__width100 generalStyles__textCenter'
							showSearch
							optionFilterProp='children'
							allowClear={false}
							value={liftSelected.id !== "" ? liftSelected.id : null}
							placeholder={t("general.noLiftSelected")}
							onChange={onChangeFilterLift}
						>
							{lifts.map((item: TerminalLiftsInterface) => (
								<Option key={item.id} value={item.id}>
									{item.name}
								</Option>
							))}
						</Select>
					</div>
					<div className='generalStyles__flex'>
						<div className='generalStyles__mlFix mt-1'>
							<Checkbox
								onChange={(e) => {
									onChangeShowAllMessages(e.target.checked);
								}}
								checked={terminalShowAllMessages}
							>
								All Messages
							</Checkbox>
						</div>
						<div className='generalStyles__mlFix mt-1'>
							<Checkbox
								onChange={(e) => {
									onChangeAutoScroll(e.target.checked);
								}}
								checked={terminalAutoScroll}
							>
								Auto Scroll
							</Checkbox>
						</div>
						<div className='generalStyles__mlFix generalStyles__mrFix mt-1'>
							<Checkbox
								onChange={(e) => {
									onChangeShowJsonObject(e.target.checked);
								}}
								checked={terminalShowJsonObject}
							>
								JSON Object
							</Checkbox>
						</div>
						<div>
							<Button
								className='buttonStyle__1  generalStyles__mrFix '
								onClick={onClickResetTable}
								icon={<FontAwesomeIcon className='generalStyles__mrFix ' icon={faTable} />}
							>
								Reset Table
							</Button>
						</div>
						<div>
							<Button
								className='buttonStyle__8  generalStyles__mrFix '
								onClick={onClickDownloadExcel}
								icon={<FontAwesomeIcon className='generalStyles__mrFix' icon={faDownload} />}
							>
								Download Excel File
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TerminalFilters;
