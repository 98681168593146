import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Menu, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsis,
	faTrash,
	faLock,
	faUnlock,
	faBan,
	faEdit,
	faSearch,
	faFilter,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetRolesQuery } from "../../../redux/roles/roleAPI";
import {
	updateOpenModalDeleteRole,
	updateOpenModalEditRole,
	updateOpenModalBlockUnlockRole,
	updateOpenDrawerRole,
	updateRole,
} from "../../../redux/roles/roleSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import TableActionsMenuItem from "../../components/table/TableActionsMenuItem";
import TableColumnStatus from "../../components/table/TableColumnStatus";
import TableNoDataBox from "../../components/table/TableNoDataBox";
import TableColumnOrganizationType from "../../components/table/TableColumnOrganizationType";
// FILTERS
import FilterSearchText from "../../components/filters/FilterSearchText";
import FilterSelectOrganization from "../../components/filters/FilterSelectOrganization";
import FilterSelectStatus from "../../components/filters/FilterSelectStatus";
// UTILS
import { getTableRowClass, buildFilterString } from "../../../utils/utils";
// INTERFACES
import { RootState } from "../../../app/store";
import { RoleInterface } from "../../../interfaces/User.interface";

const RolesTable = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [roles, setRoles] = useState<RoleInterface[]>([]);
	const [selectedItem, setSelectedItem] = useState<RoleInterface>({
		name: "",
		org_id: "",
		id: "",
		active: true,
		menu: { name: "", active: true, offline: true, pages: [] },
	});
	const [filterName, setFilterName] = useState("");
	const [filterOrganizationName, setFilterOrganizationName] = useState("");
	const [filterStatus, setFilterStatus] = useState("");
	const [pagination, setPagination] = useState<any>({
		current: 1,
		pageSize: 10,
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "50", "100"],
		total: 0,
	});
	const [errorMessage, setErrorMessage] = useState<any>("");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: RootState) => state.user);
	const { roleRefreshTable } = useSelector((state: RootState) => state.role);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetRolesTable, { data, isLoading, isError, isFetching, error }] = useLazyGetRolesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalDelete = () => {
		dispatch(updateRole(selectedItem));
		dispatch(updateOpenModalDeleteRole(true));
	};

	const handleOpenModalEdit = () => {
		dispatch(updateRole(selectedItem));
		dispatch(updateOpenModalEditRole(true));
	};

	const handleOpenModalLockUnlock = () => {
		dispatch(updateRole(selectedItem));
		dispatch(updateOpenModalBlockUnlockRole(true));
	};

	const onCellClick = (record: RoleInterface) => {
		if (record) {
			dispatch(updateRole(record));
			dispatch(updateOpenDrawerRole(true));
		}
	};

	const getData = async (page: number, limit: number) => {
		const searchAndFilterString = buildFilterString({
			filterName,
			filterOrganizationFullName: filterOrganizationName,
			filterStatus,
		});
		const token = await GETJwtToken();
		triggerGetRolesTable({
			page,
			limit,
			token,
			searchAndFilterString,
		});
	};

	const handleTableChange = async (pagination_: any) => {
		setPagination({
			...pagination,
			current: pagination_.current,
			pageSize: pagination_.pageSize,
			total: pagination_.total,
		});
		getData(pagination_.current, pagination_.pageSize);
	};

	const refreshTable = () => {
		setFilterName("");
		setFilterOrganizationName("");
		setFilterStatus("");
		setPagination({
			...pagination,
			current: 1,
			pageSize: 10,
		});
		getData(1, 10);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getData(1, 10);
	}, []);

	useEffect(() => {
		if (data && data.data) {
			setPagination({
				...pagination,
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: data.total,
			});
			setRoles(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (isError) {
			setRoles([]);
			setPagination({
				...pagination,
				current: 1,
				pageSize: 10,
				total: 0,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (roleRefreshTable) {
			refreshTable();
		}
	}, [roleRefreshTable]);

	useEffect(() => {
		if (error) {
			setErrorMessage(error);
		}
	}, [error]);

	// ************************************************ */
	// ROW ACTIONS ************************************ */
	const manageActions = () => {
		const BLANK = {
			label: (
				<div className='generalStyles__tableActionBox'>
					<span>
						<FontAwesomeIcon className='generalStyles__error generalStyles__mrFix' icon={faBan} />
						{t("general.noActions")}
					</span>
				</div>
			),
			key: "1",
		};

		const EDIT = validatePermission("roles-update", permissions)
			? {
					label: (
						<TableActionsMenuItem icon={faEdit} text={t("organizationManagement.edit")} onClick={handleOpenModalEdit} />
					),
					key: "1",
			  }
			: null;

		const LOCK_UNLOCK = validatePermission("roles-update", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={selectedItem.active ? faLock : faUnlock}
							text={selectedItem.active ? t("organizationManagement.block") : t("organizationManagement.unlock")}
							onClick={handleOpenModalLockUnlock}
						/>
					),
					key: "2",
			  }
			: null;

		const DELETE = validatePermission("roles-delete", permissions)
			? {
					label: (
						<TableActionsMenuItem
							icon={faTrash}
							text={t("organizationManagement.delete")}
							onClick={handleOpenModalDelete}
						/>
					),
					key: "3",
			  }
			: null;

		// RETURN OBJECT WITH VALID ACTIONS
		if (selectedItem) {
			if (selectedItem && selectedItem.name && selectedItem.name !== process.env.REACT_APP_ROLE_MASTER_NAME) {
				if (EDIT || DELETE || LOCK_UNLOCK) {
					return [EDIT, DELETE, LOCK_UNLOCK];
				}
			}
		}
		return [BLANK];
	};

	const menu = <Menu items={manageActions()} />;

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='mt-3'>
			{validatePermission("roles-view", permissions) ? (
				<Table
					locale={{
						emptyText: (
							<TableNoDataBox
								isError={isError}
								errorMessage={
									errorMessage && errorMessage.data && errorMessage.data.message ? errorMessage.data.message : "Error"
								}
								noItemsFound='No roles found'
							/>
						),
					}}
					rowClassName={(record, index) => getTableRowClass(index, theme)}
					className='customTable'
					dataSource={roles}
					loading={isLoading || isFetching}
					rowKey={(record) => record.id}
					size='small'
					pagination={pagination}
					onChange={handleTableChange}
					scroll={{ x: 900 }}
					footer={() => (
						<div className='generalStyles__flexEnd'>
							<Tag>{pagination.total} Roles</Tag>
						</div>
					)}
				>
					{/** NAME */}
					<Column
						onCell={(record: RoleInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("general.name")}
						dataIndex='name'
						key='name'
						width='200px'
						filterIcon={<FontAwesomeIcon icon={faSearch} />}
						filterDropdown={() => (
							<FilterSearchText
								placeHolder={t("general.name")}
								filterValue={filterName}
								setFilterValue={setFilterName}
								refreshTable={refreshTable}
								refreshParameters={[]}
							/>
						)}
						render={(text) => <div className='generalStyles__ellipsis__200px'>{text}</div>}
					/>
					{/** ORGANIZATION */}
					<Column
						onCell={(record: RoleInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("organizationManagement.organization")}
						dataIndex='organization'
						key='organization'
						width='200px'
						filterIcon={<FontAwesomeIcon icon={faFilter} />}
						filterDropdown={() => (
							<FilterSelectOrganization
								placeHolder={t("organizationManagement.organization")}
								setFilterValue={setFilterOrganizationName}
								filterValue={filterOrganizationName}
								refreshTable={refreshTable}
								refreshParameters={[]}
							/>
						)}
						render={(text, record: RoleInterface) => (
							<div>{record && record.orgs && record.orgs.name ? record.orgs.name : ""}</div>
						)}
					/>
					{/** ORGANIZATION - TYPE */}
					<Column
						title={t("general.organizationType")}
						dataIndex='organizationType'
						key='organizationType'
						width='200px'
						render={(text, record: RoleInterface) => (
							<TableColumnOrganizationType type={record && record.orgs && record.orgs.type ? record.orgs.type : ""} />
						)}
					/>
					{/** STATUS */}
					<Column
						onCell={(record: RoleInterface) => ({
							onClick: () => {
								onCellClick(record);
							},
						})}
						title={t("organizationManagement.status")}
						dataIndex='status'
						key='status'
						width='200px'
						filterIcon={<FontAwesomeIcon icon={faFilter} />}
						filterDropdown={() => (
							<FilterSelectStatus
								placeHolder={t("general.status")}
								setFilterValue={setFilterStatus}
								refreshTable={refreshTable}
								filterValue={filterStatus}
								refreshParameters={[]}
							/>
						)}
						render={(text, record: RoleInterface) => <TableColumnStatus active={record.active} />}
					/>
					{/** ACTIONS */}
					<Column
						title={t("organizationManagement.actions")}
						key='action'
						width='50px'
						render={(_: any, record: RoleInterface) => (
							<Dropdown overlay={menu} trigger={["click"]}>
								<Space
									className='generalStyles__hoverCursor'
									onClick={() => {
										setSelectedItem(record);
									}}
								>
									<FontAwesomeIcon icon={faEllipsis} />
								</Space>
							</Dropdown>
						)}
					/>
				</Table>
			) : (
				<div className='generalStyles__noAccessToListTable'>
					<Empty description={t("organizationManagement.listNotAvailableRoles")} image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}
		</div>
	);
};

export default RolesTable;
