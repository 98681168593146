import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const configurationFileAPI = createApi({
	reducerPath: "configurationFileAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: [
		"tagGetConfigurationFiles",
		"tagPostConfigurationFile",
		"tagPutConfigurationFile",
		"tagDeleteConfigurationFile",
		"tagGetAllOffsetsTypes",
		"tagGetSpecialSwitchesCatalog",
		"tagGetSpecialSwitches",
		"tagGetDataBlocks",
		"tagGetDataBlocksTypes",
		"tagGetVendors",
		"tagGetIconDefinition",
		"tagGetOffsetsByDataBlock",
	],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET ONE FULL CONFIGURATION */
		getConfiguration: builder.query({
			query: (body: any) => ({
				url: `/configurations/${body.configurationId}`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET ALL CONFIGURATION FILES */
		getConfigurationFiles: builder.query({
			query: (body: any) => ({
				url: `/configurations/?page=${body.page}&limit=${body.limit}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* GET ALL OFFSET TYPES */
		getAllOffsetsTypes: builder.query({
			query: (body: any) => ({
				url: `/offset-type/?page=1&limit=9999`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetAllOffsetsTypes"],
		}),
		//* *********************************************************** */
		//* GET OFFSET BY DATA BLOCK */
		getOffsetsByDataBlock: builder.query({
			query: (body: any) => ({
				url: `/data-blocks/offsets/${body.dataBlockID}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetOffsetsByDataBlock"],
		}),
		//* *********************************************************** */
		//* CREATE OFFSET TYPE */
		postOffsetType: builder.mutation({
			query: (body: any) => ({
				url: `/offset-type/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAllOffsetsTypes"],
		}),
		//* *********************************************************** */
		//* DELETE OFFSET TYPE BY DATA BLOCK */
		deleteOffsetTypeByDataBlock: builder.mutation({
			query: (body: any) => ({
				url: `/data-blocks/offsets/`,
				method: "delete",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles", "tagGetOffsetsByDataBlock"],
		}),
		//* *********************************************************** */
		//* DELETE OFFSET TYPE */
		deleteOffsetType: builder.mutation({
			query: (body: any) => ({
				url: `/offset-type/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAllOffsetsTypes"],
		}),
		//* *********************************************************** */
		//* UPDATE OFFSET TYPE */
		putOffsetType: builder.mutation({
			query: (body: any) => ({
				url: `/offset-type/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetAllOffsetsTypes"],
		}),
		//* *********************************************************** */
		//* CREATE CONFIGURATION FILE */
		postConfiguration: builder.mutation({
			query: (body: any) => ({
				url: "/configurations/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* CREATE CONFIGURATION FILE COMPLETE */
		postConfigurationFile: builder.mutation({
			query: (body: any) => ({
				url: "/configurations/complete",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* POST DEFINITION */
		postDefinition: builder.mutation({
			query: (body: any) => ({
				url: `/data-blocks/definitions/${body.id}`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),

		//* *********************************************************** */
		//* UPDATE DEFINITION */
		putDefinition: builder.mutation({
			query: (body: any) => ({
				url: `/data-blocks/definitions/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* CREATE OFFSETS */
		postOffset: builder.mutation({
			query: (body: any) => ({
				url: "/offsets/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: [],
		}),
		//* *********************************************************** */
		//* EDIT OFFSETS BULK */
		putOffsetsBulk: builder.mutation({
			query: (body: any) => ({
				url: `/offsets/bulk`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* EDIT CONFIGURATION FILE */
		putConfigurationFile: builder.mutation({
			query: (body: any) => ({
				url: `/configurations/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* DELETE CONFIGURATION FILE */
		deleteConfigurationFile: builder.mutation({
			query: (body: any) => ({
				url: `/configurations/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* GET ALL SPECIAL SWITCHES */
		getAllSpecialSwitches: builder.query({
			query: (body: any) => ({
				url: `/special-definitions/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetSpecialSwitches"],
		}),
		//* *********************************************************** */
		//* CREATE SPECIAL SWITCH */
		postSpecialSwitch: builder.mutation({
			query: (body: any) => ({
				url: `/special-definitions/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetSpecialSwitches"],
		}),
		//* *********************************************************** */
		//* EDIT SPECIAL SWTICH */
		putSpecialSwitch: builder.mutation({
			query: (body: any) => ({
				url: `/special-definitions/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetSpecialSwitches"],
		}),
		//* *********************************************************** */
		//* DELETE SPECIAL SWITCH */
		deleteSpecialSwitch: builder.mutation({
			query: (body: any) => ({
				url: `/special-definitions/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetSpecialSwitches"],
		}),
		//* *********************************************************** */
		//* GET ALL SPECIAL SWITCHES CATALOG */
		getAllSpecialSwitchesCatalog: builder.query({
			query: (body: any) => ({
				url: `/special-catalogs/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetSpecialSwitchesCatalog"],
		}),
		//* *********************************************************** */
		//* CREATE SPECIAL SWITCH CATALOG */
		postSpecialSwitchCatalog: builder.mutation({
			query: (body: any) => ({
				url: "/special-catalogs/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetSpecialSwitchesCatalog"],
		}),
		//* *********************************************************** */
		//* EDIT SPECIAL SWTICH CATALOG */
		putSpecialSwitchCatalog: builder.mutation({
			query: (body: any) => ({
				url: `/special-catalogs/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetSpecialSwitchesCatalog"],
		}),
		//* *********************************************************** */
		//* DELETE SPECIAL SWITCH CATALOG */
		deleteSpecialSwitchCatalog: builder.mutation({
			query: (body: any) => ({
				url: `/special-catalogs/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetSpecialSwitchesCatalog"],
		}),
		//* *********************************************************** */
		//* GET ALL DATA BLOCKS */
		getAllDataBlocks: builder.query({
			query: (body: any) => ({
				url: `/data-blocks/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetDataBlocks"],
		}),
		//* *********************************************************** */
		//* GET DATA BLOCK */
		getDataBlock: builder.query({
			query: (body: any) => ({
				url: `/data-blocks/${body.dataBlockID}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetDataBlocks"],
		}),
		//* *********************************************************** */
		//* CREATE DATA BLOCK */
		postDataBlock: builder.mutation({
			query: (body: any) => ({
				url: "/data-blocks/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetDataBlocks"],
		}),
		//* *********************************************************** */
		//* EDIT DATA BLOCK */
		putDataBlock: builder.mutation({
			query: (body: any) => ({
				url: `/data-blocks/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetDataBlocks"],
		}),
		//* *********************************************************** */
		//* DELETE DATA BLOCK */
		deleteDataBlock: builder.mutation({
			query: (body: any) => ({
				url: `/data-blocks/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetDataBlocks"],
		}),
		//* *********************************************************** */
		//* GET ALL VENDORS */
		getVendors: builder.query({
			query: (body: any) => ({
				url: `/vendors/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetVendors"],
		}),
		//* *********************************************************** */
		//* CREATE VENDOR */
		postVendor: builder.mutation({
			query: (body: any) => ({
				url: "/vendors/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetVendors"],
		}),
		//* *********************************************************** */
		//* EDIT VENDOR */
		putVendor: builder.mutation({
			query: (body: any) => ({
				url: `/vendors/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetVendors"],
		}),
		//* *********************************************************** */
		//* DELETE VENDOR */
		deleteVendor: builder.mutation({
			query: (body: any) => ({
				url: `/vendors/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetVendors"],
		}),
		//* *********************************************************** */
		//* GET ALL ICON DEFINITIONS */
		getIconDefinitions: builder.query({
			query: (body: any) => ({
				url: `/icons/?&page=1&limit=9999`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetIconDefinition"],
		}),
		//* *********************************************************** */
		//* CREATE ICON DEFINITION */
		postIconDefinition: builder.mutation({
			query: (body: any) => ({
				url: "/icons/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetIconDefinition"],
		}),
		//* *********************************************************** */
		//* EDIT ICON DEFINITION */
		putIconDefinition: builder.mutation({
			query: (body: any) => ({
				url: `/icons/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetIconDefinition"],
		}),
		//* *********************************************************** */
		//* DELETE ICON DEFINITION */
		deleteIconDefinition: builder.mutation({
			query: (body: any) => ({
				url: `/icons/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetIconDefinition"],
		}),
		//* *********************************************************** */
		//* GET ALL DATA BLOCK */
		getDataBlocks: builder.query({
			query: (body: any) => ({
				url: `/data-blocks`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* CREATE DATA BLOCK */
		postDataBlockComplete: builder.mutation({
			query: (body: any) => ({
				url: "/data-blocks/complete",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* EDIT DATA BLOCK OFFSET TYPE */
		putDataBlockComplete: builder.mutation({
			query: (body: any) => ({
				url: `/data-blocks/complete`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetConfigurationFiles"],
		}),
		//* *********************************************************** */
		//* GET ALL DATA BLOCK TYPES */
		getDataBlockTypes: builder.query({
			query: (body: any) => ({
				url: `/data-block-type/`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetDataBlocksTypes"],
		}),
		//* *********************************************************** */
		//* CREATE DATA BLOCK TYPES */
		postDataBlockTypes: builder.mutation({
			query: (body: any) => ({
				url: "/data-block-type/",
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetDataBlocksTypes"],
		}),
		//* *********************************************************** */
		//* EDIT DATA BLOCK TYPES */
		putDataBlockTypes: builder.mutation({
			query: (body: any) => ({
				url: `/data-block-type/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetDataBlocksTypes"],
		}),
		//* *********************************************************** */
		//* DELETE DATA BLOCK TYPES */
		deleteDataBlockTypes: builder.mutation({
			query: (body: any) => ({
				url: `/data-block-type/${body.id}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetDataBlocksTypes"],
		}),
		//* *********************************************************** */
	}),
});

export const {
	useLazyGetConfigurationQuery,
	useLazyGetConfigurationFilesQuery,
	useLazyGetAllOffsetsTypesQuery,
	usePostOffsetTypeMutation,
	useLazyGetOffsetsByDataBlockQuery,
	usePutOffsetTypeMutation,
	useDeleteOffsetTypeMutation,
	usePutOffsetsBulkMutation,
	usePostConfigurationFileMutation,
	usePostConfigurationMutation,
	usePostDefinitionMutation,
	usePutDefinitionMutation,
	usePostOffsetMutation,
	usePutConfigurationFileMutation,
	useDeleteConfigurationFileMutation,
	useLazyGetAllSpecialSwitchesCatalogQuery,
	usePostSpecialSwitchCatalogMutation,
	usePutSpecialSwitchCatalogMutation,
	useDeleteSpecialSwitchCatalogMutation,
	useLazyGetAllSpecialSwitchesQuery,
	usePostSpecialSwitchMutation,
	usePutSpecialSwitchMutation,
	useDeleteSpecialSwitchMutation,
	useLazyGetAllDataBlocksQuery,
	useLazyGetDataBlockQuery,
	usePostDataBlockMutation,
	usePutDataBlockMutation,
	useDeleteDataBlockMutation,
	useLazyGetVendorsQuery,
	usePostVendorMutation,
	usePutVendorMutation,
	useDeleteVendorMutation,
	useLazyGetIconDefinitionsQuery,
	usePostIconDefinitionMutation,
	usePutIconDefinitionMutation,
	useDeleteIconDefinitionMutation,
	useLazyGetDataBlocksQuery,
	usePostDataBlockCompleteMutation,
	usePutDataBlockCompleteMutation,
	useLazyGetDataBlockTypesQuery,
	usePostDataBlockTypesMutation,
	usePutDataBlockTypesMutation,
	useDeleteDataBlockTypesMutation,
	useDeleteOffsetTypeByDataBlockMutation,
} = configurationFileAPI;
