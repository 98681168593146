/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { UserInterface } from "../../interfaces/Notification.interface";

export interface NotificationState {
	user: UserInterface;
	levelBreadcrumb: number;
	openDrawerManageSchedules: boolean;
}

const initialState: NotificationState = {
	user: {
		id: "",
		role: {},
		org: {},
		schedule: {
			availability: [],
			exceptions: [],
		},
		first_name: "",
		status: "",
		last_name: "",
		middle_name: "",
		offline_psw: "",
		email: "",
		notifications: [],
		contact_info: {},
		role_id: "",
		org_id: "",
	},
	levelBreadcrumb: 0,
	openDrawerManageSchedules: false,
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<any>) => {
			state.user = action.payload;
		},
		updateLevelbreadcrumb: (state, action: PayloadAction<number>) => {
			state.levelBreadcrumb = action.payload;
		},
		updateOpenDrawerManageSchedules: (state, action: PayloadAction<boolean>) => {
			state.openDrawerManageSchedules = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateUser, updateLevelbreadcrumb, updateOpenDrawerManageSchedules } = notificationSlice.actions;

export default notificationSlice.reducer;
