import React, { useEffect, useState } from "react";
// REDUX
import { useSelector } from "react-redux";
// ICONS DARK DOWN SEQUENCE
import downDark from "../../../assets/icons/escalator/es-dark-down.gif";
// ICONS DARK UP SEQUENCE
import upDark from "../../../assets/icons/escalator/es-dark-up.gif";
// ICONS LIGHT DOWN SEQUENCE
import downLight from "../../../assets/icons/escalator/es-light-down.gif";
// ICONS LIGHT UP SEQUENCE
import upLight from "../../../assets/icons/escalator/es-light-up.gif";
// ICONS STATUS
import iconLockedDark from "../../../assets/icons/escalator/es-dark-locked.svg";
import iconLockedLight from "../../../assets/icons/escalator/es-light-locked.svg";
import darkStopped from "../../../assets/icons/escalator/es-dark-stopped.svg";
import iconLightStopped from "../../../assets/icons/escalator/es-light-stopped.svg";
// INTERFACES
import { MonitoringIconsEscalatorsInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringIconsEscalators({
	direction,
	shortMessage,
	iconSize = 40,
	mapViewClass = "",
}: MonitoringIconsEscalatorsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [liftDirection, setLiftDirection] = useState("STOPPED");
	const [liftStatus, setLiftStatus] = useState("STOPPED");
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		const validRunStates = ["esrun", "run"];
		const validStopStates = ["esstop", "stop"];
		if (shortMessage && shortMessage !== "") {
			if (validRunStates.includes(shortMessage.toLowerCase())) {
				setLiftStatus("RUN");
			}
			if (validStopStates.includes(shortMessage.toLowerCase())) {
				setLiftStatus("STOPPED");
			}
		}
	}, [shortMessage]);

	useEffect(() => {
		const validUpStates = ["up", "esup"];
		const validDownStates = ["down", "esdown", "dn"];
		if (direction && direction !== "") {
			if (validUpStates.includes(direction.toLowerCase())) {
				setLiftDirection("UP");
			}
			if (validDownStates.includes(direction.toLowerCase())) {
				setLiftDirection("DOWN");
			}
		}
	}, [direction]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`${mapViewClass || "monitoringView__controlPanelActionIcon__escalator"}`}>
			{liftDirection && liftStatus ? (
				<>
					{/** **************************************************************** */}
					{/** STOPPED DARK / BLUE  */}
					{liftStatus === "STOPPED" && (theme === "dark" || theme === "blue") && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={darkStopped} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** STOPPED LIGHT */}
					{liftStatus === "STOPPED" && theme === "light" && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={iconLightStopped} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** DOWN DARK / BLUE SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "DOWN" && (theme === "dark" || theme === "blue") && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={downDark} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** UP DARK / BLUE SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "UP" && (theme === "dark" || theme === "blue") && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={upDark} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** DOWN LIGHT SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "DOWN" && theme === "light" && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={downLight} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** UP LIGHT SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "UP" && theme === "light" && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={upLight} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
				</>
			) : (
				<>
					{/** **************************************************************** */}
					{/** NOT ALLOWED LIGHT */}
					{theme === "light" && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={iconLockedLight} alt='' />
						</div>
					)}
					{/** NOT ALLOWED LIGHT */}
					{(theme === "dark" || theme === "blue") && (
						<div style={{ width: "fit-content", height: "fit-content" }}>
							<img src={iconLockedDark} alt='' />
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default MonitoringIconsEscalators;
