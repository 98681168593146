import React from "react";
// ANT DESIGN COMPONENTS
import { DownOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTools } from "@fortawesome/free-solid-svg-icons";
import {
  updateFloorsEnabled,
  updateNamesEnabled,
  updateServiceModeEnabled,
  updateShowOriginal,
} from "../../../redux/mapView/mapViewSlice";
import { usePutFavoriteGridsMutation } from "../../../redux/monitoring/monitoringAPI";
// INTERFACE
import { RootState } from "../../../app/store";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function MonitoringMapViewConfig() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { serviceModeEnabled, floorsEnabled, namesEnabled, showOriginal } =
    useSelector((state: any) => state.mapView);
  const {
    cascaderSelectMultipleSelectionOptions,
    topViewLayout,
    detailViewLayout,
    statusViewLayout,
    favoriteGridName,
  } = useSelector((state: RootState) => state.monitoring);
  const { organizationSelectMultipleData } = useSelector(
    (state: RootState) => state.organization
  );
  const { userLogged } = useSelector((state: RootState) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [putSaveConfiguration] = usePutFavoriteGridsMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const saveCurrentConfiguration = async () => {
    const token = await GETJwtToken();
    const id = userLogged.pref_grid_id;
    const mapViewConfiguration = {
      serviceModeEnabled,
      floorsEnabled,
      namesEnabled,
      showOriginal,
    };
    const BODY = {
      token,
      id,
      body: {
        grid: JSON.stringify({
          topViewLayout,
          detailViewLayout,
          statusViewLayout,
          organizationSelectMultipleData,
          cascaderSelectMultipleSelectionOptions,
          mapViewConfiguration,
        }),
        name: favoriteGridName,
        query: JSON.stringify(cascaderSelectMultipleSelectionOptions),
        user_id: userLogged.id,
      },
    };
    putSaveConfiguration(BODY);
  };

  const handleOTS = () => {
    dispatch(updateServiceModeEnabled(!serviceModeEnabled));
  };

  const handleFloor = () => {
    dispatch(updateFloorsEnabled(!floorsEnabled));
  };

  const handleNames = () => {
    dispatch(updateNamesEnabled(!namesEnabled));
  };

  const handleShowOriginal = () => {
    dispatch(updateShowOriginal(!showOriginal));
  };

  // ************************************************ */
  // USE EFFECT ************************************* */
  React.useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    saveCurrentConfiguration();
  }, [serviceModeEnabled, floorsEnabled, namesEnabled, showOriginal]);

  // ************************************************ */
  // COMPONENT ************************************** */
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <FontAwesomeIcon icon={namesEnabled ? faEye : faEyeSlash} /> Unit
          Names
        </>
      ),
      onClick: handleNames,
    },
    {
      key: "2",
      label: (
        <>
          <FontAwesomeIcon icon={floorsEnabled ? faEye : faEyeSlash} /> Floors
        </>
      ),
      onClick: handleFloor,
    },
    {
      key: "3",
      label: (
        <>
          <FontAwesomeIcon icon={serviceModeEnabled ? faEye : faEyeSlash} />{" "}
          Service Mode
        </>
      ),
      onClick: handleOTS,
    },
    {
      key: "4",
      label: (
        <>
          <FontAwesomeIcon icon={showOriginal ? faEye : faEyeSlash} /> Original
        </>
      ),
      onClick: handleShowOriginal,
    },
  ];
  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Dropdown
        menu={{
          items,
        }}
        open={open}
      >
        <Space
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTools} />
          <DownOutlined />
        </Space>
      </Dropdown>
      {/** ************************* */}
      {/** BODY */}
    </div>
  );
}

export default MonitoringMapViewConfig;
