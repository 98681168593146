import React from "react";
// REDUX
import { useSelector } from "react-redux";
// INTERFACES
import { RootState } from "../../../app/store";

function ControlPanelBankVIP() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`montoringView__controlPanel__${theme} generalStyles__flex  `} style={{ padding: "20px" }}>
			VIP
		</div>
	);
}

export default ControlPanelBankVIP;
