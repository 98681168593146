import React from "react";
// ANT DESIGN COMPONENTS
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import NotificationBanksTable from "./NotificationBanksTable";
// INTERFACES
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

function NotificationBanksPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`layout__contentPage__${theme}`}>
			{validatePermission("banks-view", permissions) ? <NotificationBanksTable /> : <UnauthorizedPage />}
		</div>
	);
}

export default NotificationBanksPage;
