import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostGeneralCurrentOutOfServiceMutation } from "../../../redux/reports/chartsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const ReportGeneralCurrentOutOfService = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);
	const [dataTable, setDataTable] = useState();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] = usePostGeneralCurrentOutOfServiceMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const generateTableRequestBody = ({ downloadReport }: any) => {
		const body: any = {
			server_id: filters.serverId,
			timezone: filters.timezoneCode,
			download: downloadReport,
		};
		// Include lift type if value is not ALL
		if (filters.liftType !== "ALL") {
			body.lift_type = filters.liftType;
		}
		return body;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	useEffect(() => {
		// Sort report data
		// Filter 1: status = Out of Service
		// Filter 2: Sort by bank name alphabetic
		// Filter 3: Sort by lift name alphabetic
		if (data && data.results && data.results.length > 0) {
			const copy = structuredClone(data);
			const sortedData = copy.results.sort((a: any, b: any) => {
				if (a.status.toLowerCase() === "out of service" && b.status.toLowerCase() !== "out of service") return -1;
				if (a.status.toLowerCase() !== "out of service" && b.status.toLowerCase() === "out of service") return 1;
				if (a.bank_name !== b.bank_name) {
					return a.bank_name.localeCompare(b.bank_name);
				}
				return a.lift_name.localeCompare(b.lift_name);
			});
			copy.results = sortedData;
			setDataTable(copy);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission("view-report-management-general-current-out-of-service", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "SERVERS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: ["bank_name", "lift_name", "status", "date", "floor_name"],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText3",
							displayDownloadCSVReport: true,
							displayDownloadPDFReport: true,
							reportTitle: "General / Current Out of Service",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: false,
							displayDate: false,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
								{ id: "MONTH", name: "Month" },
								{ id: "QUARTER", name: "Quarter" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
						}}
					/>
					<ReportsTable
						response={dataTable}
						generateTableRequestBody={generateTableRequestBody}
						csvUrl={`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/all-banks/out-of-service`}
						displayExpandibleRowTable={false}
						groupColumns={null}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportGeneralCurrentOutOfService;
