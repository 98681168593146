/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigurationTableInterface } from "../../interfaces/Configurations.interface";

export interface ConfigurationFileState {
	configurationFile: ConfigurationTableInterface;
	offsetType: Object;
	offsetTypeName: string;
	offsetTypeTagColor: string;
	formCreateEdit: string;
	openModalCreateEditNewConfigurationFile: boolean;
	expandDrawerCreateConfigurationFile: boolean;
	openModalDeleteConfigurationFile: boolean;
	openDrawerBankFiles: boolean;
	showSpecialSwitches: boolean;
	openDrawerConfigurationFile: boolean;
	openModalOffsetsTable: boolean;
	configurationFileTableParams: Object;
	configurationFileRefreshTable: boolean;
	configurationFileList: Array<any>;
	configurationFileBreadcrumb: Array<any>;
	configurationFileUpdateBreadcrumb: Object;
	configurationFileForm: Object;
	openModalUnsavedChangesDefinition: boolean;
	configurationDefaultOffsets: boolean;
	openDrawerCreateDataBlock: boolean;
	clearFilters: boolean;
	openModalDownloadOffsetsConfigurationFile: boolean;
}

const initialState: ConfigurationFileState = {
	configurationFile: {
		created_at: "",
		updated_at: "",
		deleted_at: "",
		lift_type: "ELEVATOR",
		name: "",
		data_blocks: [],
		vendor: {},
		lifts: 1,
		stops: 2,
		id: "",
		vendor_id: "",
		rear_door: true,
		rowSpan: 1,
		data_block: {},
	},
	offsetType: {},
	offsetTypeName: "",
	offsetTypeTagColor: "",
	formCreateEdit: "CREATE",
	openModalCreateEditNewConfigurationFile: false,
	expandDrawerCreateConfigurationFile: false,
	openModalDeleteConfigurationFile: false,
	openDrawerBankFiles: false,
	showSpecialSwitches: false,
	openDrawerConfigurationFile: false,
	openModalOffsetsTable: false,
	configurationFileTableParams: {
		pagination: {
			current: 1,
			pageSize: 10,
			showSizeChanger: true,
			pageSizeOptions: ["10", "20", "50", "100"],
		},
	},
	configurationFileRefreshTable: false,
	configurationFileList: [],
	configurationFileBreadcrumb: [],
	configurationFileUpdateBreadcrumb: {
		update: false,
		id: "",
		name: "",
	},
	configurationFileForm: {
		step1: {},
		step2: {},
		step3: {},
		step4: {},
		step5: { carOffsets: [] },
	},
	openModalUnsavedChangesDefinition: false,
	configurationDefaultOffsets: true,
	openDrawerCreateDataBlock: false,
	clearFilters: false,
	openModalDownloadOffsetsConfigurationFile: false,
};

export const configurationFileSlice = createSlice({
	name: "configurationFile",
	initialState,
	reducers: {
		updateConfigurationFile: (state, action: PayloadAction<ConfigurationTableInterface>) => {
			state.configurationFile = action.payload;
		},
		updateOffsetType: (state, action: PayloadAction<Object>) => {
			state.offsetType = action.payload;
		},
		updateOffsetTypeName: (state, action: PayloadAction<string>) => {
			state.offsetTypeName = action.payload;
		},
		updateOffsetTypeTagColor: (state, action: PayloadAction<string>) => {
			state.offsetTypeTagColor = action.payload;
		},
		updateOpenModalCreateEditNewConfigurationFile: (state, action: PayloadAction<boolean>) => {
			state.openModalCreateEditNewConfigurationFile = action.payload;
		},
		updateExpandDrawerCreateNewConfigurationFile: (state, action: PayloadAction<boolean>) => {
			state.expandDrawerCreateConfigurationFile = action.payload;
		},
		updateOpenModalDeleteConfigurationFile: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteConfigurationFile = action.payload;
		},
		updateOpenDrawerConfigurationFile: (state, action: PayloadAction<boolean>) => {
			state.openDrawerConfigurationFile = action.payload;
		},
		updateShowSpecialSwitches: (state, action: PayloadAction<boolean>) => {
			state.showSpecialSwitches = action.payload;
		},
		updateOpenModalOffsetsTable: (state, action: PayloadAction<boolean>) => {
			state.openModalOffsetsTable = action.payload;
		},
		updateOpenDrawerBankFiles: (state, action: PayloadAction<boolean>) => {
			state.openDrawerBankFiles = action.payload;
		},
		updateConfigurationFileTableParams: (state, action: PayloadAction<Object>) => {
			state.configurationFileTableParams = action.payload;
		},
		updateConfigurationFileRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.configurationFileRefreshTable = action.payload;
		},
		updateConfigurationFileList: (state, action: PayloadAction<any>) => {
			state.configurationFileList = action.payload;
		},
		updateConfigurationFileBreadcrumb: (state, action: PayloadAction<any>) => {
			state.configurationFileBreadcrumb = action.payload;
		},
		updateConfigurationFileUpdateBreadcrumb: (state, action: PayloadAction<Object>) => {
			state.configurationFileUpdateBreadcrumb = action.payload;
		},
		updateConfigurationFileForm: (state, action: PayloadAction<Object>) => {
			state.configurationFileForm = action.payload;
		},
		updateOpenModalUnsavedChangesDefinition: (state, action: PayloadAction<boolean>) => {
			state.openModalUnsavedChangesDefinition = action.payload;
		},
		updateConfigurationDefaultOffsets: (state, action: PayloadAction<boolean>) => {
			state.configurationDefaultOffsets = action.payload;
		},
		updateOpenDrawerCreateDataBlock: (state, action: PayloadAction<boolean>) => {
			state.openDrawerCreateDataBlock = action.payload;
		},
		updateClearFilters: (state, action: PayloadAction<boolean>) => {
			state.clearFilters = action.payload;
		},
		updateOpenModalDownloadOffsetsConfigurationFile: (state, action: PayloadAction<boolean>) => {
			state.openModalDownloadOffsetsConfigurationFile = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateExpandDrawerCreateNewConfigurationFile,
	updateConfigurationFile,
	updateOffsetType,
	updateOffsetTypeName,
	updateOffsetTypeTagColor,
	updateOpenModalCreateEditNewConfigurationFile,
	updateOpenModalDeleteConfigurationFile,
	updateOpenDrawerBankFiles,
	updateShowSpecialSwitches,
	updateOpenDrawerConfigurationFile,
	updateOpenModalOffsetsTable,
	updateConfigurationFileTableParams,
	updateConfigurationFileRefreshTable,
	updateConfigurationFileList,
	updateConfigurationFileBreadcrumb,
	updateConfigurationFileUpdateBreadcrumb,
	updateConfigurationFileForm,
	updateOpenModalUnsavedChangesDefinition,
	updateConfigurationDefaultOffsets,
	updateOpenDrawerCreateDataBlock,
	updateClearFilters,
	updateOpenModalDownloadOffsetsConfigurationFile,
	updateFormCreateEdit,
} = configurationFileSlice.actions;

export default configurationFileSlice.reducer;
