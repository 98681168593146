import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostAllBanksPerformanceMutation } from "../../../redux/reports/reportsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const ReportGeneralPerformance = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] = usePostAllBanksPerformanceMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const generateTableRequestBody = ({ downloadReport }: any) => {
		const body: any = {
			server_id: filters.serverId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
			exclusions: {
				saturdays: filters.exclusionSaturdays ? "true" : "false",
				sundays: filters.exclusionSundays ? "true" : "false",
				holidays: filters.exclusionHolidays ? "true" : "false",
				time_ranges: filters.exclusionTimeList.join(","),
				date_ranges: filters.exclusionDateList.join(","),
			},
			download: downloadReport,
		};
		// Include lift type if value is not ALL
		if (filters.liftType !== "ALL") {
			body.lift_type = filters.liftType;
		}
		return body;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission("view-report-management-general-performance", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "SERVERS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [
								"bank_name",
								"lift_name",
								"lift_type",
								"total_interruptions",
								"end_date",
								"start_date",
								"availability",
								"unavailability",
							],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText3",
							displayDownloadCSVReport: true,
							displayDownloadPDFReport: true,
							reportTitle: "General / Performance",
							displayAdvanceFiltersExclusions: true,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: true,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "MONTH", name: "Month" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 6,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
						}}
					/>
					<ReportsTable
						response={data}
						generateTableRequestBody={generateTableRequestBody}
						csvUrl={`${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/reports/all-banks/performance`}
						displayExpandibleRowTable
						groupColumns={null}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportGeneralPerformance;
