import React, { useEffect, useState } from "react";
// REDUX
import { useSelector } from "react-redux";
// ICONS DARK FORWARD SEQUENCE
import fordwardDark from "../../../assets/icons/movingWalk/we-dark-forward.gif";
// ICONS DARK BACKWARD SEQUENCE
import backwardDark from "../../../assets/icons/movingWalk/we-dark-backward.gif";
// ICONS LIGHT FORWARD SEQUENCE
import fordwardLight from "../../../assets/icons/movingWalk/we-light-forward.gif";
// ICONS LIGHT BACKWARD SEQUENCE
import backwardLight from "../../../assets/icons/movingWalk/we-light-backward.gif";
// ICONS STATUS
import iconLockedDark from "../../../assets/icons/movingWalk/we-dark-locked.svg";
import iconLockedLight from "../../../assets/icons/movingWalk/we-light-locked.svg";
import iconDarkStopped from "../../../assets/icons/movingWalk/we-dark-stopped.svg";
import iconLightStopped from "../../../assets/icons/movingWalk/we-light-stopped.svg";
// INTERFACES
import { MonitoringIconsMovingWalksInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function MonitoringIconsMovingWalks({
	shortMessage,
	direction,
	iconSize = 40,
	mapViewClass = "",
}: MonitoringIconsMovingWalksInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [liftDirection, setLiftDirection] = useState("STOPPED");
	const [liftStatus, setLiftStatus] = useState("STOPPED");

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		const validRunStates = ["esrun", "run"];
		const validStopStates = ["esstop", "stop"];
		if (shortMessage && shortMessage !== "") {
			if (validRunStates.includes(shortMessage.toLowerCase())) {
				setLiftStatus("RUN");
			}
			if (validStopStates.includes(shortMessage.toLowerCase())) {
				setLiftStatus("STOPPED");
			}
		}
	}, [shortMessage]);

	useEffect(() => {
		const validForwardStates = ["esforward", "forward"];
		const validBackwardStates = ["esbackward", "backward"];
		if (direction && direction !== "") {
			if (validForwardStates.includes(direction.toLowerCase())) {
				setLiftDirection("FORWARD");
			}
			if (validBackwardStates.includes(direction.toLowerCase())) {
				setLiftDirection("BACKWARD");
			}
		}
	}, [direction]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`${mapViewClass || "monitoringView__controlPanelActionIcon__escalator"}`}>
			{liftDirection && liftStatus ? (
				<>
					{/** **************************************************************** */}
					{/** STOPPED DARK / BLUE  */}
					{liftStatus === "STOPPED" && (theme === "dark" || theme === "blue") && (
						<div>
							<img src={iconDarkStopped} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** STOPPED LILGHT  */}
					{liftStatus === "STOPPED" && theme === "light" && (
						<div>
							<img src={iconLightStopped} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** FORWARD DARK / BLUE SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "FORWARD" && (theme === "dark" || theme === "blue") && (
						<div>
							<img src={fordwardDark} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** BACKWARD DARK / BLUE SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "BACKWARD" && (theme === "dark" || theme === "blue") && (
						<div>
							<img src={backwardDark} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** FORWARD LIGHT SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "FORWARD" && theme === "light" && (
						<div>
							<img src={fordwardLight} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** BACKWARD LIGHT SEQUENCE */}
					{liftStatus === "RUN" && liftDirection === "BACKWARD" && theme === "light" && (
						<div>
							<img src={backwardLight} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
				</>
			) : (
				<>
					{/** NOT ALLOWED LIGHT */}
					{theme === "light" && (
						<div>
							<img src={iconLockedLight} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
					{/** NOT ALLOWED LIGHT */}
					{(theme === "dark" || theme === "blue") && (
						<div>
							<img src={iconLockedDark} alt='' height={iconSize} width={iconSize} />
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default MonitoringIconsMovingWalks;
