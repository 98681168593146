import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// INTERFACES
import { FormInputColorPickerInterface } from "../../../interfaces/Components.interface";

const FormInputColorPicker = ({ setColor, color }: FormInputColorPickerInterface) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newColor = event.target.value;
		setColor(newColor);
	};

	const onChangeSelectColor = (newColor: string) => {
		setColor(newColor);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__flex'>
			<div className='generalStyles__width100'>
				<Select
					className='generalStyles__width100'
					size='small'
					onChange={(event: any) => {
						onChangeSelectColor(event);
					}}
					value={color}
					optionFilterProp='children'
					filterOption={(input: string, option: any) => option.children.toLowerCase().includes(input.toLowerCase())}
				>
					{[
						{ id: "#38ac68", name: "SUCCESS" },
						{ id: "#2968c0", name: "INFO" },
						{ id: "#fed03d", name: "WARNING" },
						{ id: "#f94d4f", name: "ERROR" },
					].map((item) => (
						<Option key={item.id} value={item.id}>
							{item.name}
						</Option>
					))}
				</Select>
			</div>
			<div className='generalStyles__mlFix'>
				<input
					type='color'
					value={color}
					onChange={handleChange}
					style={{
						border: "none",
						cursor: "pointer",
						width: "75px",
						height: "24px",
						padding: 0,
					}}
				/>
			</div>
		</div>
	);
};

export default FormInputColorPicker;
