import React from "react";
// NAVIGATION
import { useNavigate, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";

library.add(faUser);

function NotificationUsersBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = new URLSearchParams(location.search).get("idBank");
	const idNotification1 = new URLSearchParams(location.search).get("idNotification1");
	const idOrganization = new URLSearchParams(location.search).get("idOrganization");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const navigateToBanks = () => {
		navigate({
			pathname: "/notification-management/notifications-banks",
		});
	};

	const navigateToNotifications = () => {
		navigate({
			pathname: "/notification-management/notifications",
			search: idBank ? `?idBank=${idBank}&idOrganization=${idOrganization}` : "",
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb>
			<Breadcrumb.Item>{t("general.notificationManagement")}</Breadcrumb.Item>
			{idBank && idBank !== "" && (
				<Breadcrumb.Item className='generalStyles__breadcrumbItemInactive' onClick={navigateToBanks}>
					{t("general.banks")}
				</Breadcrumb.Item>
			)}
			{idNotification1 && idNotification1 !== "" && (
				<Breadcrumb.Item className='generalStyles__breadcrumbItemInactive' onClick={navigateToNotifications}>
					{t("general.notifications")}
				</Breadcrumb.Item>
			)}
			<Breadcrumb.Item className='generalStyles__breadcrumbItem'>{t("general.users")}</Breadcrumb.Item>
		</Breadcrumb>
	);
}

export default NotificationUsersBreadcrumb;
