import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Button, Radio, Spin, Empty, Switch } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
import {
	usePostMonitoringCommandMutation,
	usePostEmulatorCommandMutation,
} from "../../../redux/monitoring/monitoringAPI";
// INTERFACES
import { ControlPanelElevatorInterface, ControlPanelBankFloors } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// ICONS
import elLockedDark from "../../../assets/icons/elevatorStatus/el-locked-dark.svg";

function ControlPanelElevator({
	currentFloor,
	hasFrontDoors,
	hasRearDoors,
	bankFloors,
	frontLock,
	rearLock,
	serviceMode,
}: ControlPanelElevatorInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [doorSelected, setDoorSelected] = useState<"FRONT" | "REAR">("FRONT");
	const [spinningFloorNo, setSpinningFloorNo] = useState<number>(0);
	const [secureCalls, setSecureCalls] = useState<boolean>(false);
	const [elevatorCurrentFloor, setElevatorCurrentFloor] = useState(0);
	const [showLoading, setShowLoading] = useState<boolean>(true);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, layoutSelected, socketConnection } = useSelector((state: RootState) => state.monitoring);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postCommand, { isLoading: isLoadingPostCommand }] = usePostMonitoringCommandMutation();
	const [postCommandEmulator, { isLoading: isLoadingPostCommandEmulator }] = usePostEmulatorCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickCarCall = async (floor: number) => {
		const token = await GETJwtToken();
		if (socketConnection !== "EMULATOR") {
			const BODY = {
				token,
				body: {
					cmd_adapter: layoutSelected && layoutSelected.typeChannel && layoutSelected.typeChannel === "ADAPTER",
					cmd_off: false,
					lift_id: liftSelected.id,
					short_msg: `${secureCalls ? "S" : ""}E${doorSelected === "REAR" ? "R" : ""}CC${floor}`,
					type: "CONTROL",
				},
				serverId: layoutSelected.idServer,
			};
			postCommand(BODY);
		} else {
			const BODY = {
				token,
				body: {
					type: "CARCALL",
					lift_id: liftSelected.id,
					floor,
					bank_id: layoutSelected.idBank,
					front_rear: doorSelected.toLowerCase(),
				},
				serverId: layoutSelected.idServer,
			};
			postCommandEmulator(BODY);
		}
	};

	const onClickVipMode = async () => {
		const token = await GETJwtToken();
		if (socketConnection !== "EMULATOR") {
			const BODY = {
				token,
				body: {
					cmd_adapter: layoutSelected && layoutSelected.typeChannel && layoutSelected.typeChannel === "ADAPTER",
					cmd_off: false,
					lift_id: liftSelected.id,
					short_msg: "",
					type: "CONTROL",
				},
				serverId: layoutSelected.idServer,
			};
			postCommand(BODY);
		} else {
			const BODY = {
				token,
				body: {
					type: "VIP",
					lift_id: liftSelected.id,
					floor: currentFloor,
					bank_id: layoutSelected.idBank,
					front_rear: doorSelected.toLowerCase(),
				},
				serverId: layoutSelected.idServer,
			};
			postCommandEmulator(BODY);
		}
	};

	const onChangeSecureCalls = (checked: boolean) => {
		setSecureCalls(checked);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		setShowLoading(true);
		setTimeout(() => {
			setShowLoading(false);
		}, 500);
	}, []);

	useEffect(() => {
		if (currentFloor) {
			setElevatorCurrentFloor(currentFloor);
		}
	}, [currentFloor]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='monitoringView__detailsBox'>
			<div
				className={`montoringView__controlPanel montoringView__controlPanel__${theme}`}
				style={{
					minHeight: "400px",
				}}
			>
				<Spin
					spinning={showLoading || isLoadingPostCommand || isLoadingPostCommandEmulator}
					style={{ paddingTop: "250px" }}
				>
					{!showLoading && (
						<>
							{/** ACTIONS */}
							{(hasFrontDoors || hasRearDoors) && (
								<div className={` drawer__box__control__panel__${theme} mt-3`}>
									<div className='generalStyles__spaceBetween'>
										<div className='generalStyles__flex'>
											<Radio.Group
												value={doorSelected}
												onChange={(e) => {
													setDoorSelected(e.target.value);
												}}
											>
												<Radio value='FRONT'>{t("configuration.configurationFiles.frontDoors")}</Radio>
												<Radio value='REAR'>{t("configuration.configurationFiles.rearDoors")}</Radio>
											</Radio.Group>
										</div>
										{layoutSelected &&
											layoutSelected.idOrganization &&
											layoutSelected.idOrganization === "88bed3b9-28b1-452f-a2bb-f4a29646bbf8" && (
												<>
													<div>
														{secureCalls && (
															<FontAwesomeIcon
																icon={faLock}
																fontSize={18}
																className='monitoring__modalSettings__icon generalStyles__mrFix'
															/>
														)}
														<span className='generalStyles__mrFix' style={{ marginTop: "5px" }}>
															{t("general.secureCalls")}
														</span>
														<Switch checked={secureCalls} onChange={onChangeSecureCalls} />
													</div>
												</>
											)}
										{validatePermission("monitor-control-panel-vip-mode", permissions) && (
											<>
												<div>
													{secureCalls && (
														<FontAwesomeIcon
															icon={faLock}
															fontSize={18}
															className='monitoring__modalSettings__icon generalStyles__mrFix'
														/>
													)}
													<span className='generalStyles__mrFix' style={{ marginTop: "5px" }}>
														{t("general.vipMode")}
													</span>
													<Switch checked={serviceMode.toUpperCase() === "VIP"} onChange={onClickVipMode} />
												</div>
											</>
										)}
									</div>
								</div>
							)}
							{/** PANEL / CIRCLE BUTTONS */}
							<div className={`drawer__box__control__panel__${theme} mt-3`}>
								<div className='monitoringView__controlPanelPanel'>
									{hasFrontDoors || hasRearDoors ? (
										<>
											{bankFloors.map((floor: ControlPanelBankFloors) => (
												<div key={floor.id}>
													<Button
														className={`monitoring__modalSettings__button__1 ${
															liftSelected && elevatorCurrentFloor === floor.floor_no
																? `monitoringView__controlPanelNumber__active${
																		serviceMode.toUpperCase() === "VIP" ? "__vip" : ""
																  }`
																: `monitoringView__controlPanelNumber__${theme}`
														}`}
														shape='circle'
														onClick={() => {
															onClickCarCall(floor.floor_no);
															setSpinningFloorNo(floor.floor_no);
														}}
														disabled={
															(doorSelected === "FRONT" && !floor.front_door) ||
															(doorSelected === "REAR" && !floor.rear_door) ||
															(frontLock.includes(floor.floor_no) && doorSelected === "FRONT") ||
															(rearLock.includes(floor.floor_no) && doorSelected === "REAR")
														}
													>
														{(frontLock.includes(floor.floor_no) && doorSelected === "FRONT") ||
														(rearLock.includes(floor.floor_no) && doorSelected === "REAR") ? (
															<img src={elLockedDark} alt='' />
														) : (
															<>
																{(isLoadingPostCommand || isLoadingPostCommandEmulator) &&
																spinningFloorNo === floor.floor_no ? (
																	<Spin
																		spinning={
																			(isLoadingPostCommand || isLoadingPostCommandEmulator) &&
																			spinningFloorNo === floor.floor_no
																		}
																	/>
																) : (
																	<span style={{ marginTop: "3px" }}>
																		{floor && floor.name && floor.name !== ""
																			? floor.name.substring(0, 3).toUpperCase()
																			: floor.floor_no}
																	</span>
																)}
															</>
														)}
													</Button>
												</div>
											))}
										</>
									) : (
										<Empty description={<span>{t("general.bankHasNoFrontRearDoors")}</span>} />
									)}
								</div>
							</div>
						</>
					)}
				</Spin>
			</div>
		</div>
	);
}

export default ControlPanelElevator;
