import React from "react";
// ANT DESIGN COMPONENTS
import { Tooltip } from "antd";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateGlobalOrganizationName,
	updateGlobalOrganizationId,
	updateGlobalRolId,
	updateGlobalRolName,
} from "../../redux/home/homeSlice";
// COMPONENTS
import DrawerRowDivider from "../components/drawer/DrawerRowDivider";
import SelectOrganization from "../components/select/SelectOrganization";
import SelectRole from "../components/select/SelectRol";
// INTERFACES
import { RootState } from "../../app/store";

function GlobalConfiguration() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme, globalOrganizationId, globalRolId } = useSelector((state: RootState) => state.home);
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeIdOrganization = (newValue: string, record: any) => {
		if (record && record.children) {
			dispatch(updateGlobalOrganizationId(newValue));
			dispatch(updateGlobalOrganizationName(record.children));
		}
	};

	const onChangeIdRol = (newValue: string, record: any) => {
		if (record && record.children) {
			dispatch(updateGlobalRolId(newValue));
			dispatch(updateGlobalRolName(record.children));
		}
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider icon={faGears} title={t("general.globalConfiguration")} />
			<div className={`drawer__box__${theme}`}>
				{/** ORGANIZATION */}
				<div className='col-md-12 mt-3'>
					<div className='generalStyles__flex'>
						<div className='generalStyles__inputFlexNameLarge'>{t("general.organization")}</div>
						<div className='generalStyles__width100'>
							<SelectOrganization
								value={globalOrganizationId}
								onChangeIdOrganization={onChangeIdOrganization}
								disabledInput={false}
							/>
						</div>
						<div style={{ width: "52px" }} />
					</div>
				</div>
				{/** ROLE */}
				<div className='col-md-12 mt-3'>
					<div className='generalStyles__flex'>
						<div className='generalStyles__inputFlexNameLarge'>{t("general.role")}</div>
						<div className='generalStyles__width100'>
							<SelectRole value={globalRolId} onChangeIdRol={onChangeIdRol} disabledInput={false} />
						</div>
						<div>
							<Tooltip title={t("tooltip.globalRoleConfiguration")} placement='top'>
								<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon' />
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GlobalConfiguration;
