import React, { useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateLiftSelected,
	updateBankSelected,
	updateTerminalAutoScroll,
	updateTerminalIdLiftSelected,
	updateTerminalLastShortMessage,
	updateTerminalResetTable,
	updateTerminalShowJsonObject,
	updateTerminalShowAllMessages,
} from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import TerminalFilters from "./TerminalFilters";
import TerminalTable from "./TerminalTable";
import TerminalLift from "./TerminalLift";
import TerminalJsonViewer from "./TerminalJsonViewer";
// INTERFACES
import { TerminalPageInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function TerminalPage({ socketMessage, layouts }: TerminalPageInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		bank_name: "",
		lift_name: "",
		car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			down: {
				front: [],
				rear: [],
				front_lock: [],
				rear_lock: [],
			},
			up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
			down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
		},
		faults: false,
		position: 1,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
		direction: "",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { terminalShowJsonObject, terminalShowAllMessages } = useSelector((state: RootState) => state.monitoring);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			dispatch(
				updateLiftSelected({
					id: "",
					bank_id: "",
					control_panel: {},
					direction: "",
					name: "",
					position: 0,
					type: "",
					lift_floors: [],
				})
			);
			dispatch(
				updateBankSelected({
					id: "",
					active: false,
					board: 0,
					columns: 0,
					floor_below_lobby: 0,
					floor_count: 0,
					logical_port: 0,
					name: "",
					number_of_lifts: 0,
					channel_id: "",
					rear_doors: false,
					stops: 0,
					type: "",
					control_panel: {},
					bank_floors: [],
					lifts: [],
				})
			);
			dispatch(updateTerminalAutoScroll(true));
			dispatch(updateTerminalIdLiftSelected(""));
			dispatch(updateTerminalLastShortMessage(BLANK_SOCKET_MESSAGE));
			dispatch(updateTerminalResetTable(false));
			dispatch(updateTerminalShowJsonObject(false));
			dispatch(updateTerminalShowAllMessages(false));
		};
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`terminal__drawer__container__${theme}`}>
			<TerminalFilters layouts={layouts} />
			<br />
			<div className='generalStyles__flex'>
				{!terminalShowAllMessages && (
					<div className='terminal__liftContainer'>
						<TerminalLift layouts={layouts} />
					</div>
				)}
				<div className='terminal__drawer__table'>
					<div className='terminal__drawer__tableContainer'>
						<TerminalTable socketMessage={socketMessage} />
					</div>
				</div>
				{terminalShowJsonObject && (
					<div className='terminal__jsonContainer'>
						<TerminalJsonViewer />
					</div>
				)}
			</div>
		</div>
	);
}

export default TerminalPage;
