import React, { useEffect, useState } from "react";
// NAVIGATION
import { useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Switch, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCircleCheck, faCircleXmark, faClockFour } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	useLazyGetUsersByOrganizationQuery,
	usePostAssignNotificationToUserMutation,
	useDeleteAssignNotificationToUserMutation,
	notificationAPI,
	useLazyGetNotificationQuery,
} from "../../../redux/notifications/notificationAPI";
import { updateUser, updateOpenDrawerManageSchedules } from "../../../redux/notifications/notificationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { RootState } from "../../../app/store";
import { UserInterface, NotificationInterface } from "../../../interfaces/Notification.interface";

function NotificationUsersTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	const location = useLocation();
	const idOrganization = new URLSearchParams(location.search).get("idOrganization");
	const idNotification1 = new URLSearchParams(location.search).get("idNotification1");
	const idNotification2 = new URLSearchParams(location.search).get("idNotification2");
	const idNotification3 = new URLSearchParams(location.search).get("idNotification3");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [users, setUsers] = useState<UserInterface[]>([]);
	const [usersResponse, setUsersResponse] = useState<any>([]);
	const [total, setTotal] = useState(0);
	const [responseError, setResponseError] = useState<any>();
	const [disableSwitch, setDisableSwitch] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetUsers,
		{ data: dataGetUsers, error: errorGetUsers, isError: isErrorGetUsers, isLoading: isLoadingGetUsers },
	] = useLazyGetUsersByOrganizationQuery();
	const [
		triggerGetNotification,
		{ error: errorGetNotification, isError: isErrorGetNotification, isLoading: isLoadingGetNotification },
	] = useLazyGetNotificationQuery();

	const [
		triggerPostAssignNotification,
		{
			isLoading: isLoadingPostAssign,
			isSuccess: isSuccessPostAssign,
			isError: isErrorPostAssign,
			error: errorPostAssign,
		},
	] = usePostAssignNotificationToUserMutation();

	const [
		triggerDeleteAssignNotification,
		{ isLoading: isLoadingPutAssign, isSuccess: isSuccessPutAssign, isError: isErrorPutAssign, error: errorPutAssign },
	] = useDeleteAssignNotificationToUserMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const displayLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 2000);
	};

	const postUser = async (user: UserInterface, notificationType: string) => {
		displayLoading();
		const index = notifications.findIndex((x: any) => x.type === notificationType);
		const token = await GETJwtToken();
		triggerPostAssignNotification({
			token,
			body: {
				muted: notifications[index].muted,
				notification_id: notifications[index].id,
				user_id: user.id,
			},
		});
	};

	const putUser = async (user: UserInterface, notificationType: string) => {
		displayLoading();
		const index = notifications.findIndex((x: any) => x.type === notificationType);
		const token = await GETJwtToken();
		triggerDeleteAssignNotification({
			token,
			notificationId: notifications[index].id,
			userId: user.id,
		});
	};

	const getInitialData = async () => {
		dispatch(notificationAPI.util.resetApiState());
		const token = await GETJwtToken();
		const searchAndFilterString = `?filter_target=organization_id&filter=${idOrganization}&page=1&limit=99999&sort_direction=desc`;
		triggerGetUsers({
			token,
			searchAndFilterString,
		});
		const results = [];
		// eslint-disable-next-line no-restricted-syntax
		for (const idNotification of [idNotification1, idNotification2, idNotification3]) {
			// eslint-disable-next-line no-await-in-loop
			const result = await triggerGetNotification({
				token,
				idNotification,
			});
			if (result && result.data && result.data.data) {
				results.push(result.data.data);
			}
		}
		setNotifications(results);
	};

	const checkIfUserHasNotification = (user: UserInterface, notificationType: string) => {
		let notificationExist = false;
		if (user && user.notifications && user.notifications.length > 0 && notifications && notifications.length > 0) {
			user.notifications.map((item: NotificationInterface) => {
				if (
					item &&
					item.type === notificationType &&
					notifications.some((obj: NotificationInterface) => obj.id === item.id)
				) {
					notificationExist = true;
				}
				return true;
			});
		}
		return notificationExist;
	};

	const buildUsers = () => {
		const newUsers: any = [];
		usersResponse.map((user: UserInterface) => {
			newUsers.push({
				isActiveWhatsapp: checkIfUserHasNotification(user, "WHATSAPP"),
				isActiveEmail: checkIfUserHasNotification(user, "EMAIL"),
				isActiveSms: checkIfUserHasNotification(user, "SMS"),
				...user,
			});
			return true;
		});
		const sortedUsers = newUsers.sort((a: any, b: any) => a.email.localeCompare(b.email));
		setUsers(sortedUsers);
	};

	const disableNotificationSwitch = () => {
		let disableItem = true;
		if (notifications && notifications.length > 0) {
			disableItem = false;
		}
		setDisableSwitch(disableItem);
	};

	const handleOpenDrawerConfigureSchedules = (record: UserInterface) => {
		dispatch(updateUser(record));
		dispatch(updateOpenDrawerManageSchedules(true));
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getInitialData();
	}, []);

	useEffect(() => {
		if (errorGetUsers || errorPostAssign || errorPutAssign || errorGetNotification) {
			setResponseError(errorGetUsers || errorPostAssign || errorPutAssign || errorGetNotification);
		}
	}, [errorGetUsers, errorPostAssign, errorPutAssign, errorGetNotification]);

	useEffect(() => {
		if (dataGetUsers && dataGetUsers.data) {
			setUsersResponse(dataGetUsers.data);
		}
		if (dataGetUsers && dataGetUsers.total) {
			setTotal(dataGetUsers.total);
		}
	}, [dataGetUsers]);

	useEffect(() => {
		disableNotificationSwitch();
	}, [notifications]);

	useEffect(() => {
		if (usersResponse && usersResponse.length > 0 && notifications && notifications.length > 0) {
			buildUsers();
		}
	}, [usersResponse, notifications]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={<FontAwesomeIcon icon={faArrowsRotate} className='generalStyles__mrFix' />}
					onClick={() => {
						getInitialData();
					}}
				>
					{t("general.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isErrorGetUsers || isErrorGetNotification}
							errorMessage={
								responseError && responseError.data && responseError.data.message ? responseError.data.message : "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={
					isLoadingGetUsers || isLoadingPostAssign || isLoadingPutAssign || isLoadingGetNotification || isLoading
				}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={users}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				rowKey={(record) => record.email}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** USER EMAIL */}
				<Column
					title={t("general.email")}
					dataIndex='email'
					key='email'
					sorter={(a: UserInterface, b: UserInterface) => a.email.localeCompare(b.email)}
					render={(text: string, record: UserInterface) => <div>{record && record.email ? record.email : "--"}</div>}
				/>
				{/** SMS */}
				<Column
					title='SMS'
					dataIndex='isActiveSms'
					key='isActiveSms'
					render={(value, record: UserInterface) => (
						<Tooltip placement='topLeft' title={disableSwitch ? t("general.notificationNotAvailable") : ""}>
							<Switch
								disabled={disableSwitch}
								checked={value}
								onChange={(checked: boolean) => {
									if (checked) {
										postUser(record, "SMS");
									} else {
										putUser(record, "SMS");
									}
								}}
							/>{" "}
						</Tooltip>
					)}
				/>
				{/** EMAIL */}
				<Column
					title='Email'
					dataIndex='isActiveEmail'
					key='isActiveEmail'
					render={(value, record: UserInterface) => (
						<Tooltip placement='topLeft' title={disableSwitch ? t("general.notificationNotAvailable") : ""}>
							<Switch
								disabled={disableSwitch}
								checked={value}
								onChange={(checked: boolean) => {
									if (checked) {
										postUser(record, "EMAIL");
									} else {
										putUser(record, "EMAIL");
									}
								}}
							/>
						</Tooltip>
					)}
				/>
				{/** WHATSAPP */}
				<Column
					title='Whatsapp'
					dataIndex='isActiveWhatsapp'
					key='isActiveWhatsapp'
					render={(value, record: UserInterface) => (
						<Tooltip placement='topLeft' title={disableSwitch ? t("general.notificationNotAvailable") : ""}>
							<Switch
								disabled={disableSwitch}
								checked={value}
								onChange={(checked: boolean) => {
									if (checked) {
										postUser(record, "WHATSAPP");
									} else {
										putUser(record, "WHATSAPP");
									}
								}}
							/>
						</Tooltip>
					)}
				/>
				{/** SCHEDULES */}
				<Column
					title={t("general.schedules")}
					dataIndex='schedules'
					key='schedules'
					render={(value, record: UserInterface) => (
						<>
							{record && record.schedule && record.schedule.availability && record.schedule.availability.length > 0 ? (
								<Tag className='generalStyles__width50px generalStyles__textCenter' color='green'>
									<FontAwesomeIcon icon={faCircleCheck} />
								</Tag>
							) : (
								<Tag className='generalStyles__width50px generalStyles__textCenter' color='red'>
									<FontAwesomeIcon icon={faCircleXmark} />
								</Tag>
							)}
						</>
					)}
				/>
				{/** ASSIGN SCHEDULES */}
				<Column
					title={t("general.assignSchedules")}
					dataIndex='schedules'
					key='schedules'
					align='center'
					render={(value, record: UserInterface) => (
						<FontAwesomeIcon
							onClick={() => {
								handleOpenDrawerConfigureSchedules(record);
							}}
							icon={faClockFour}
							style={{ fontSize: "18px", cursor: "pointer" }}
							className='generalStyles__info__hover'
						/>
					)}
				/>
			</Table>
			<GlobalAlert2
				isError={isErrorPostAssign}
				isSuccess={isSuccessPostAssign}
				requestType='POST'
				error={errorPostAssign}
				name={t("general.assignNotification")}
			/>
			<GlobalAlert2
				isError={isErrorPutAssign}
				isSuccess={isSuccessPutAssign}
				requestType='PUT'
				error={errorPutAssign}
				name={t("general.assignNotification")}
			/>
		</div>
	);
}

export default NotificationUsersTable;
