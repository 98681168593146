import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
	}),
	{ maxRetries }
);

export const notificationAPI = createApi({
	reducerPath: "notificationAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: ["tagGetNotifications", "tagGetNotification", "tagGetMessages", "tagGetUsers"],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET BANKS*/
		getBanks: builder.query({
			query: (body: any) => ({
				url: `/banks/${body.searchAndFilterString}`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET BANKS BY ORGANIZATION*/
		getBanksByOrganization: builder.query({
			query: (body: any) => ({
				url: `/banks/org/${body.searchAndFilterString}`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET BANK NOTIFICATIONS */
		getBankNotifications: builder.query({
			query: (body: any) => ({
				url: `/banks/notifications/${body.searchAndFilterString}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetNotifications"],
		}),
		//* *********************************************************** */
		//* GET BANK MESSAGES */
		getBankMessages: builder.query({
			query: (body: any) => ({
				url: `/banks/messages/${body.bankId}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetMessages"],
		}),
		//* *********************************************************** */
		//* GET BANK NOTIFICATIONS ASSIGNED */
		getBankNotificationsAssigned: builder.query({
			query: (body: any) => ({
				url: `/banks/notifications-assignments/`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET USERS BY ORGANIZATION */
		getUsersByOrganization: builder.query({
			query: (body: any) => ({
				url: `/users/${body.searchAndFilterString}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetUsers"],
		}),
		//* *********************************************************** */
		//* GET NOTIFICATION */
		getNotification: builder.query({
			query: (body: any) => ({
				url: `/notifications/${body.idNotification}`,
				headers: { authorization: body.token },
			}),
			providesTags: ["tagGetNotification"],
		}),
		//* *********************************************************** */
		//* POST ASSIGN NOTIFICATION TO A USER  */
		postAssignNotificationToUser: builder.mutation({
			query: (body: any) => ({
				url: `/notifications/assign/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetUsers"],
		}),
		//* *********************************************************** */
		//* DELETE ASSIGN NOTIFICATION TO A USER  */
		deleteAssignNotificationToUser: builder.mutation({
			query: (body: any) => ({
				url: `/notifications/assign/${body.notificationId}/${body.userId}`,
				method: "delete",
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetUsers"],
		}),
		//* *********************************************************** */
		//* ASSIGN SCHEDULE TO USER  */
		putUser: builder.mutation({
			query: (body: any) => ({
				url: `/users/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetUsers"],
		}),
		//* *********************************************************** */
		//* POST NOTIFICATION  */
		postNotification: builder.mutation({
			query: (body: any) => ({
				url: `/notifications/`,
				method: "post",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetNotifications"],
		}),
		//* *********************************************************** */
		//* PUT NOTIFICATION  */
		putNotification: builder.mutation({
			query: (body: any) => ({
				url: `/notifications/${body.id}`,
				method: "put",
				body: body.body,
				headers: { authorization: body.token },
			}),
			invalidatesTags: ["tagGetNotifications"],
		}),
	}),
});

// https://api.dev.agilegoods.com/api/users/?&page=1&limit=10

export const {
	useLazyGetBanksQuery,
	useLazyGetBanksByOrganizationQuery,
	useLazyGetBankNotificationsQuery,
	useLazyGetBankMessagesQuery,
	useLazyGetUsersByOrganizationQuery,
	useLazyGetBankNotificationsAssignedQuery,
	usePostAssignNotificationToUserMutation,
	useDeleteAssignNotificationToUserMutation,
	usePostNotificationMutation,
	usePutNotificationMutation,
	useLazyGetNotificationQuery,
	usePutUserMutation,
} = notificationAPI;
