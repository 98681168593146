import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Tooltip, Button, message, Empty } from "antd";
// TIMEZONE
import moment from "moment-timezone";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetLiftReportQuery, usePostFaultRecentReportMutation } from "../../../redux/reports/reportsAPI";
import { updateFaults, updateExistsFaultsInLiftSelected } from "../../../redux/monitoring/monitoringSlice";
// INTERFACES
import { RootState } from "../../../app/store";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

function ControlPanelFaults() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Column } = Table;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [faults, setFaults] = useState<any>([]);
	const [isLoadingFaults, setIsLoadingFaults] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, existsFaultsInLiftSelected } = useSelector((state: RootState) => state.monitoring);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isFetching }] = useLazyGetLiftReportQuery();
	const [triggerGetReport2, { data: data2, isLoading: isLoading2 }] = usePostFaultRecentReportMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getFaultMessages = async (liftId: string) => {
		const token: string = await GETJwtToken();
		triggerGetReport({
			page: 1,
			limit: 10,
			token: token || "",
			searchAndFilterString: `start_date=${moment(new Date())
				.subtract(15, "days")
				.format("YYYY-MM-DD")}T00:00:00.000Z&end_date=${moment(new Date()).format(
				"YYYY-MM-DD"
			)}T23:59:59.999Z&filter_target=msg.type%7Clift_id&filter=FAULT%7C${liftId}&timezone=${moment.tz.guess()}`,
		});
		triggerGetReport2({
			token: token || "",
			body: {
				bank_id: liftSelected.bank_id,
				weeks: 50,
				timezone: moment.tz.guess(),
				download: "false",
				limit: 20,
			},
		});
	};

	const clearFaults = () => {
		dispatch(updateExistsFaultsInLiftSelected(false));
		dispatch(
			updateFaults({
				liftId: liftSelected.id,
				type: "LIFT",
			})
		);
		setIsLoadingFaults(true);
		setTimeout(() => {
			setIsLoadingFaults(false);
		}, 1000);
		message.success({
			type: "success",
			content: `${t("monitoring.faultsCleared")}`,
		});
	};

	const buildReportsData = (response: any) => {
		const newData: any = [];
		response.map((item: any) => {
			newData.push({
				id: `${item.lift_name}-${item.date}-${item.floor}-${item.fault}-${item.direction}`,
				created_at: item.date,
				short_msg: item.fault,
			});
			return true;
		});
		setFaults(newData);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (liftSelected && liftSelected.id) {
			getFaultMessages(liftSelected.id);
		}
	}, [liftSelected]);

	useEffect(() => {
		if (data) {
			setFaults(data.data);
		}
	}, [data]);

	useEffect(() => {
		if (data2 && data2.results) {
			buildReportsData(data2.results);
		}
	}, [data2]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='monitoringView__detailsBox' style={{ minHeight: "400px", height: "auto" }}>
			<div
				className={`montoringView__controlPanel montoringView__controlPanel__${theme}`}
				style={{
					minHeight: "400px",
					height: "auto",
					paddingBottom: "25px",
				}}
			>
				{faults && faults.length > 0 ? (
					<>
						{/** FAULTS */}
						<Table
							rowClassName={(record, index) => getTableRowClass(index, theme)}
							className='customTable'
							dataSource={faults}
							loading={isLoading || isFetching || isLoadingFaults || isLoading2}
							rowKey={(record) => record.id}
							size='small'
						>
							{/** Index */}
							<Column
								title='No'
								width='50px'
								render={(text, record: any, index: number) => (
									<>
										<div className='generalStyles__info'>{index + 1}</div>
									</>
								)}
							/>
							{/** Created at */}
							<Column
								title='Created at'
								dataIndex='created_at'
								key='created_at'
								render={(text) => (
									<Tooltip placement='top' title={text}>
										<div>{text} </div>{" "}
									</Tooltip>
								)}
							/>
							{/** Short MSG */}
							<Column
								title='Message'
								dataIndex='short_msg'
								key='short_msg'
								render={(text) => (
									<Tooltip placement='top' title={text || "--"}>
										<div>{text || "--"}</div>
									</Tooltip>
								)}
							/>
						</Table>
						<div className='generalStyles__flexEnd '>
							{existsFaultsInLiftSelected && (
								<Button onClick={clearFaults} className='buttonStyle__4 mt-3'>
									Clear Faults
								</Button>
							)}
						</div>
					</>
				) : (
					<div
						className={`drawer__box__control__panel__${theme} generalStyles__width100 generalStyles__flexCenter mt-3`}
					>
						<Empty />
					</div>
				)}
			</div>
		</div>
	);
}

export default ControlPanelFaults;
