import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Tooltip, Empty } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBankSelected } from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import IconDefinitionIcons from "../../tools/iconDefinition/IconDefinitionIcons";
// INTERFACES
import {
	BankFloorInterface,
	LayoutInterface,
	TerminalLiftInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function TerminalLift({ layouts }: TerminalLiftInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { liftSelected, bankSelected, terminalLastShortMessage, iconDefinitionElevator } = useSelector(
		(state: RootState) => state.monitoring
	);
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (layouts) {
			layouts.map((layout: LayoutInterface) => {
				if (layout && layout.id === liftSelected.bank_id) {
					dispatch(updateBankSelected(layout));
				}
				return true;
			});
		}
	}, [liftSelected]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__mrFix'>
			{liftSelected && liftSelected.id ? (
				<>
					<div
						className={`terminal__container__${theme}`}
						style={{ width: "250px", paddingTop: "15px", paddingBottom: "35px" }}
					>
						<div style={{ fontWeight: "bolder", textAlign: "center", fontSize: "18px" }}>{liftSelected.name}</div>
						<br />
						{bankSelected && bankSelected.bank_floors && (
							<div>
								{bankSelected.bank_floors.map((floor: BankFloorInterface, floorIndex: number) => (
									<div className='generalStyles__flex' key={floorIndex}>
										{/** *********************************************************** */}
										{/** FLOORS */}
										<div
											style={{ width: "75px", marginLeft: "10px" }}
											className={`monitoring__card__floor generalStyles__flex  `}
										>
											<div
												className='generalStyles__warning'
												style={{
													width: "25px",
													textAlign: "center",
												}}
											>
												<Tooltip placement='topLeft' title='Floor Index'>
													{floor && floor.floor_index >= 0 ? floor.floor_index : "--"}
												</Tooltip>
											</div>
											<div
												style={{
													width: "25px",
													textAlign: "center",
												}}
											>
												<Tooltip placement='topLeft' title='Floor No'>
													<span>{floor && floor.floor_no >= 0 ? floor.floor_no : "--"}</span>
												</Tooltip>
											</div>
											<div
												style={{
													width: "25px",
													textAlign: "center",
												}}
												className='generalStyles__info'
											>
												<Tooltip placement='topLeft' title='Floor Name'>
													<span>{floor && floor.name ? floor.name.toUpperCase().substring(0, 2) : "--"}</span>
												</Tooltip>
											</div>
										</div>
										{/** *********************************************************** */}
										{/** DOTS AND ELEVATOR CONTAINER */}
										<div
											style={{
												width: "100px",
												position: "relative",
												marginLeft: "10px",
											}}
										>
											{/** LIFTS */}
											{bankSelected && bankSelected.lifts && (
												<div className='generalStyles__spaceEvenly monitoring__elevatorFullView__liftContainer'>
													<div>
														<div
															className={` monitoring__card__liftContainer__${theme} ${
																floorIndex === 0 ? "monitoring__card__liftContainerBorderTop " : ""
															} ${
																floorIndex === bankSelected.bank_floors.length - 1
																	? "monitoring__card__liftContainerBorderBottom"
																	: ""
															}`}
														>
															{terminalLastShortMessage &&
																terminalLastShortMessage.icon &&
																terminalLastShortMessage.position === floor.floor_no && (
																	<IconDefinitionIcons
																		isMap={false}
																		expanded={false}
																		liftType='ELEVATOR'
																		icon={iconDefinitionElevator[terminalLastShortMessage.icon.name.toLowerCase()]}
																		iconSize={20}
																	/>
																)}
															{/** HALL CALLS UP */}
															{(terminalLastShortMessage.hall_calls.up_assigned.front.includes(floor.floor_no) ||
																terminalLastShortMessage.hall_calls.up_assigned.rear.includes(floor.floor_no)) &&
																floor.floor_no !== terminalLastShortMessage.position && (
																	<IconDefinitionIcons
																		isMap={false}
																		expanded={false}
																		liftType='ELEVATOR'
																		icon='elGoalGreen'
																		iconSize={20}
																	/>
																)}
															{/** HALL CALLS DOWN */}
															{(terminalLastShortMessage.hall_calls.down_assigned.front.includes(floor.floor_no) ||
																terminalLastShortMessage.hall_calls.down_assigned.rear.includes(floor.floor_no)) &&
																floor.floor_no !== terminalLastShortMessage.position && (
																	<IconDefinitionIcons
																		isMap={false}
																		expanded={false}
																		liftType='ELEVATOR'
																		icon='elGoalRed'
																		iconSize={20}
																	/>
																)}
															{/** FRONT CAR CALLS */}
															{terminalLastShortMessage &&
																terminalLastShortMessage.car_calls &&
																terminalLastShortMessage.car_calls.front &&
																terminalLastShortMessage.car_calls.front.includes(floor.floor_no) &&
																floor.floor_no !== terminalLastShortMessage.position && (
																	<IconDefinitionIcons
																		isMap={false}
																		expanded={false}
																		liftType='ELEVATOR'
																		icon='elGoal'
																		iconSize={20}
																	/>
																)}
															{/** REAR CAR CALLS */}
															{terminalLastShortMessage &&
																terminalLastShortMessage.car_calls &&
																terminalLastShortMessage.car_calls.rear &&
																terminalLastShortMessage.car_calls.rear.includes(floor.floor_no) &&
																floor.floor_no !== terminalLastShortMessage.position && (
																	<IconDefinitionIcons
																		isMap={false}
																		expanded={false}
																		liftType='ELEVATOR'
																		icon='elGoal'
																		iconSize={20}
																	/>
																)}
														</div>
													</div>
												</div>
											)}
											{/** DOTS STYLE */}
											<div className='monitoring__elevatorFullView__dotsStyleContainer'>
												<div className={`monitoring__card__dotStyle__${theme}__inactive`} />
											</div>
										</div>
									</div>
								))}
							</div>
						)}
					</div>
					<br />
				</>
			) : (
				<div>
					<Empty />
				</div>
			)}
		</div>
	);
}

export default TerminalLift;
