import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import MonitoringHeartBeat from "../monitoring/MonitoringHeartBeat";
// INTERFACES
import { RootState } from "../../../app/store";

function ControlPanelBankHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { bank } = useSelector((state: RootState) => state.bank);
	const { layoutSelected } = useSelector((state: RootState) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`montoringView__controlPanel__${theme} generalStyles__flex  `} style={{ padding: "20px" }}>
			<div style={{ marginTop: "5px" }}>
				<MonitoringHeartBeat
					socketMessage={{
						bank_id: "",
						lift_id: "",
						lift_type: "",
						bank_name: "",
						lift_name: "",
						car_calls: { front: [], rear: [], front_lock: [], rear_lock: [] },
						hall_calls: {
							up: {
								front: [],
								rear: [],
								front_lock: [],
								rear_lock: [],
							},
							down: {
								front: [],
								rear: [],
								front_lock: [],
								rear_lock: [],
							},
							up_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
							down_assigned: { front: [], rear: [], front_lock: [], rear_lock: [] },
						},
						faults: false,
						position: 1,
						icon: {
							type: "",
							name: "",
							description: "",
						},
						service_mode: {
							name: "",
							description: "",
						},
						heartbeat: 0,
						direction: "",
					}}
				/>
			</div>
			<div>
				<div>
					<h3>{layoutSelected && layoutSelected.nameOrganization ? layoutSelected.nameOrganization : ""}</h3>
				</div>
				<div>
					<h5 className='generalStyles__info' style={{ marginTop: "-15px" }}>
						{bank && bank.name ? bank.name : ""}
					</h5>
				</div>
			</div>
		</div>
	);
}

export default ControlPanelBankHeader;
