import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Tabs } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenModalControlPanelBank } from "../../../redux/monitoring/monitoringSlice";
// COMPONENTS
import ButtonClose from "../../components/buttons/ButtonClose";
import ControlPanelBankHeader from "./ControlPanelBankHeader";
import ControlPanelBankVIP from "./ControlPanelBankVIP";
// INTERFACES
import { MonitoringModalSettingsInterface } from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

function ControlPanelBank({ socketMessage }: MonitoringModalSettingsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalControlPanelBank, controlPanelDrawerPlacement } = useSelector(
		(state: RootState) => state.monitoring
	);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [tabActiveKey, setTabActiveKey] = useState<string>("VIP");

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenModalControlPanelBank(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {}, [socketMessage]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='650'
			placement={controlPanelDrawerPlacement}
			closable={false}
			onClose={onClickClose}
			open={openModalControlPanelBank}
			footer={
				<div style={{ textAlign: "right" }}>
					<ButtonClose onClick={onClickClose} />
				</div>
			}
		>
			<div>
				<ControlPanelBankHeader />
			</div>
			<br />
			<div>
				<Tabs
					onTabClick={(key: string) => {
						setTabActiveKey(key);
					}}
					activeKey={tabActiveKey}
				>
					<Tabs.TabPane key='VIP' tab='VIP MODE'>
						<ControlPanelBankVIP />
					</Tabs.TabPane>
					<Tabs.TabPane key='HALL_CALLS' tab='HALL CALLS'>
						HALL CALLS
					</Tabs.TabPane>
					<Tabs.TabPane key='FAULTS' tab='FAULTS'>
						FAULTS
					</Tabs.TabPane>
				</Tabs>
			</div>
		</Drawer>
	);
}

export default ControlPanelBank;
