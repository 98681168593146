import React from "react";
// ANT DESIGN COMPONENTS
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import NotificationUsersTable from "./NotificationUsersTable";
import NotificationUsersSchedulesDrawer from "./NotificationUsersSchedulesDrawer";
// INTERFACES
import { RootState } from "../../../app/store";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

function NotificationUsersPage() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { permissions } = useSelector((state: any) => state.user);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`layout__contentPage__${theme}`}>
			{validatePermission("users-view", permissions) ? (
				<>
					<NotificationUsersTable />
					<NotificationUsersSchedulesDrawer />
				</>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
}

export default NotificationUsersPage;
