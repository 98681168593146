import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, InputNumber, Button, Tooltip, Alert, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
// import { updateConfigurationDefaultOffsets } from "../../../redux/configurationFiles/configurationFilesSlice";
import { updateOpenFormOffsetType, updateOpenFormOffsetTypeAction } from "../../../redux/tools/toolsSlice";
import {
	useLazyGetAllOffsetsTypesQuery,
	useDeleteOffsetTypeByDataBlockMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OffsetTypeForm from "../../tools/offsetType/OffsetTypeForm";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { RootState } from "../../../app/store";
import {
	ConfigurationFilesSelectOffsetsInterface,
	OffsetsTypesInterface,
	DataBlockInterface,
} from "../../../interfaces/Configurations.interface";

function ConfigurationFilesSelectOffsets({
	onChangeOffsets,
	onChangeSelectedItemsListOffsetsTypes,
	offsetsTypes,
	selectedItemsListOffsetsTypes,
	editDataBlockId,
}: ConfigurationFilesSelectOffsetsInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [listOffsetsTypes, setListOffsetTypes] = useState<string[]>([]);
	const filteredOptionsOffsetTypes = listOffsetsTypes.filter((o) => !selectedItemsListOffsetsTypes.includes(o));

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: any) => state.home);
	const { openModalCreateEditNewConfigurationFile, formCreateEdit } = useSelector(
		(state: RootState) => state.configurationFile
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetOffsetsTypes, { data, isLoading }] = useLazyGetAllOffsetsTypesQuery();
	const [
		triggerDeleteOffsetType,
		{
			isLoading: isLoadingDeleteOffset,
			isSuccess: isSuccessDeleteOffset,
			error: errorDeleteOffset,
			isError: isErrorDeleteOffset,
		},
	] = useDeleteOffsetTypeByDataBlockMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeTotalInput = (newValue: number, index: number, values: OffsetsTypesInterface) => {
		const newObject: OffsetsTypesInterface[] = [];
		offsetsTypes.map((item: OffsetsTypesInterface, index2: number) => {
			if (index === index2) {
				newObject.push({
					name: values.name,
					id: values.id,
					byte_count: newValue,
				});
			} else {
				newObject.push({ ...item });
			}
			return true;
		});
		onChangeOffsets(newObject);
	};

	const buildSelectedItemsObj = (items: string[]) => {
		// Build object
		if (items.length > 0) {
			const newArr: OffsetsTypesInterface[] = [];
			items.map((item: string) => {
				const offsetTypeIndex = offsetsTypes.findIndex((object: any) => object.name === item);
				if (offsetTypeIndex >= 0) {
					newArr.push({
						name: offsetsTypes[offsetTypeIndex].name,
						id: offsetsTypes[offsetTypeIndex].id,
						byte_count: offsetsTypes[offsetTypeIndex].byte_count,
					});
				} else {
					const offsetIndex = data.data.findIndex((object: DataBlockInterface) => object.name === item);
					newArr.push({
						name: item,
						id: data.data[offsetIndex].id,
						byte_count: 1,
					});
				}
				// Get index in offset list
				return true;
			});
			onChangeOffsets(newArr);
		} else {
			onChangeOffsets([]);
		}
	};

	const removeOffsetType = async (offset: string) => {
		const index = data.data.findIndex((item: any) => item.name === offset);
		if (index >= 0) {
			const token = await GETJwtToken();
			triggerDeleteOffsetType({
				body: {
					data_block_id: editDataBlockId,
					offset_type_id: data.data[index].id,
				},
				token,
			});
		}
	};

	const onChangeOffsetTypeSelect = (newSelectedItemsObj: string[]) => {
		const difference = selectedItemsListOffsetsTypes.filter((item) => !newSelectedItemsObj.includes(item));
		if (formCreateEdit === "EDIT") {
			removeOffsetType(difference[0]);
		}
		onChangeSelectedItemsListOffsetsTypes(newSelectedItemsObj);
		buildSelectedItemsObj(newSelectedItemsObj);
	};

	const getOffsetsList = async () => {
		const token = await GETJwtToken();
		triggerGetOffsetsTypes({ token });
	};

	const handleOpenModalCreateNewOffsetType = () => {
		dispatch(updateOpenFormOffsetType(true));
		dispatch(updateOpenFormOffsetTypeAction("create"));
	};

	const onClickClose = () => {
		onChangeOffsets([]);
		onChangeSelectedItemsListOffsetsTypes([]);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getOffsetsList();
	}, []);

	useEffect(() => {
		const newObject: string[] = [];
		if (data && data.data) {
			data.data.map((item: DataBlockInterface) => {
				newObject.push(item.name);
				return true;
			});
			setListOffsetTypes(newObject);
		}
	}, [data]);

	useEffect(() => {
		if (!openModalCreateEditNewConfigurationFile) {
			onClickClose();
		}
	}, [openModalCreateEditNewConfigurationFile]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoading || isLoadingDeleteOffset}>
			<div className={`drawer__box__${theme} mt-3`}>
				<div>
					{formCreateEdit === "EDIT" && (
						<Alert
							message='Warning'
							description={t("general.configurationWarmingRemoveOffsets")}
							type='warning'
							showIcon
							closable
							style={{ width: "401px" }}
						/>
					)}
				</div>
				<div className='generalStyles__flex mt-2'>
					{/** SELECT OFF TYPE OFFSETS */}
					<div className='drawer__textContainer' style={{ width: "402px" }}>
						<Select
							mode='multiple'
							value={selectedItemsListOffsetsTypes}
							onChange={(e: any) => {
								onChangeOffsetTypeSelect(e);
							}}
							className='generalStyles__width100'
							disabled={isLoading}
							options={filteredOptionsOffsetTypes.map((item) => ({
								value: item,
								label: item,
							}))}
						/>
					</div>
					<div>
						<Tooltip title={t("tooltip.configurationSelectOffsets")} placement='top'>
							<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon mt-1' />
						</Tooltip>
					</div>
				</div>
				{/** FILTERED OPTIONS */}
				{selectedItemsListOffsetsTypes && (
					<div className='container'>
						<div className='mt-3'>
							{offsetsTypes.map((item: OffsetsTypesInterface, index: number) => (
								<div className='generalStyles__flex' key={item.name}>
									<div className='generalStyles__textEllipsis' style={{ width: "250px" }}>
										<Tooltip title={item.name}>
											<p style={{ paddingTop: "5px" }}>
												<span className='generalStyles__info'>{index + 1})</span> {item.name}
											</p>
										</Tooltip>
									</div>
									<div className='generalStyles__mlFix25'>
										<InputNumber
											min={1}
											max={100}
											value={item.byte_count}
											onChange={(e: any) => {
												onChangeTotalInput(e, index, item);
											}}
										/>
										{item.byte_count === 1 && (
											<Tooltip title={t("tooltip.carServiceByte")} placement='top'>
												<FontAwesomeIcon icon={faCircleInfo} className='generalStyles__infoTooltipIcon' />
											</Tooltip>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				{/** ---------------------------------------------------- */}
				{/** ADD NEW OFFSET TYPE */}
				<div className='generalStyles__flexEnd mt-3'>
					<Tooltip title={t("organizationManagement.roles.offset-type-managercreate")} placement='top'>
						<Button shape='circle' className='buttonStyle__1' onClick={handleOpenModalCreateNewOffsetType}>
							<FontAwesomeIcon icon={faPlus} />
						</Button>
					</Tooltip>
					{/** OFFSET TYPE FORM */}
					<OffsetTypeForm />
				</div>
				{/** ---------------------------------------------------- */}
				{/** GLOBAL ALERT */}
				<GlobalAlert2
					isError={isErrorDeleteOffset}
					isSuccess={isSuccessDeleteOffset}
					requestType='DELETE'
					error={errorDeleteOffset}
					name='Offset Type'
				/>
			</div>
		</Spin>
	);
}

export default ConfigurationFilesSelectOffsets;
