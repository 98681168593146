import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useLazyGetRolesQuery } from "../../../redux/roles/roleAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { SelectRolInterface, RoleInterface } from "../../../interfaces/Roles.interface";

function SelectRol({ value, onChangeIdRol, disabledInput }: SelectRolInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [roles, setRoles] = useState<RoleInterface[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetRoles, { data, isLoading }] = useLazyGetRolesQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		const token = await GETJwtToken();
		triggerGetRoles({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const buildCustomRoles = (newData: any) => {
		const newRoles: any = [];
		newData.map((rol: any) => {
			newRoles.push({ id: rol.id, name: `${rol.orgs.name} / ${rol.name}` });
			return true;
		});
		setRoles(newRoles);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (data && data.data && data.data) {
			buildCustomRoles(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Select
			showSearch
			filterOption={(input: any, option: any) => option.children.toLowerCase().includes(input.toLowerCase())}
			className='generalStyles__width100'
			size='small'
			loading={isLoading}
			value={value}
			onChange={(item: any, record: any) => {
				onChangeIdRol(item, record);
			}}
			disabled={disabledInput}
		>
			{roles.map((item: RoleInterface) => (
				<Option key={item.id} value={item.id}>
					{item.name}
				</Option>
			))}
		</Select>
	);
}

export default SelectRol;
